import gql from "graphql-tag";

export default gql`

fragment HotsoftConfigFragment on PmsConfigHotsoft {
  providerKey
  endpoint
  hotelCode
  user
  password
  productCodeForReservationDiscount
  category
  source
  project

}

`