import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks'
import propositionPushGql from 'graphql/PMS/Proposition/mutation/propositionPush.gql'
import propositionGetGql from 'graphql/PMS/Proposition/query/propositionGet.gql'
import propositionPushUpdateGql from 'graphql/PMS/Proposition/subscription/propositionPushUpdate.gql'
import { Proposition, PropositionPushPmsEnum, PropositionPushStatusEnum, PropositionRowRoomOption } from 'model'
import React, { useEffect, useState } from 'react'

type PropositionPushProps = {
  propositionId: string,
  onDone: () => void,
}

export const PropositionPush = ({ onDone, propositionId }: PropositionPushProps) => {

  const [error, updateError] = useState<string>(null)

  const { data } = useQuery<{ result: { proposition: Proposition } }>(propositionGetGql, {
    variables: {
      id: propositionId,
    },
    skip: !propositionId
  })

  const [mutate, { loading }] = useMutation<{ proposition: Proposition }>(propositionPushGql)

  useSubscription(propositionPushUpdateGql, {
    variables: {
      id: propositionId
    },
    skip: !propositionId
  })

  const proposition: Proposition = data?.result?.proposition

  const propositionRow = proposition?.propositionRow?.find(el => el.id === proposition?.rowSelectedId)
  const pushPms = proposition?.pushPms
  const pushStatus = proposition?.pushStatus

  const onPush = (id: string) => {
    updateError(null);
    mutate({
      variables: {
        id,
      }
    })
      .then(() => {
        onDone && onDone()
      })
      .catch((e) => {
        updateError(e.message)
      })
  }


  const resaPending = pushStatus === PropositionPushStatusEnum.PUSH_NONE || pushStatus === null
  const discountPending = resaPending || pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE
  const optionsPending = discountPending || pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE
  const statusPending = optionsPending || pushStatus === PropositionPushStatusEnum.PUSH_OPTIONS_DONE

  const statusDone = pushStatus === PropositionPushStatusEnum.PUSH_CONFIRMED_DONE
  const optionsDone = statusDone || pushStatus === PropositionPushStatusEnum.PUSH_OPTIONS_DONE
  const discountDone = optionsDone || pushStatus === PropositionPushStatusEnum.PUSH_DISCOUNT_DONE
  const resaDone = discountDone || pushStatus === PropositionPushStatusEnum.PUSH_RESERVATION_DONE

  const mustConfirm = pushPms === PropositionPushPmsEnum.PROPOSITION_PUSH_POST_CONFIRMED

  const hasOptionsToPush = propositionRow?.roomList?.reduce((acc, rowRoom) => {
    return acc || rowRoom?.optionList?.length > 0
  }, false)


  const hasDiscounts = propositionRow?.roomList?.reduce((acc, rowRoom) => {
    return acc || !!rowRoom?.discount
  }, false)



  const canPush = true

  return <div>
    <h1>Push Proposition {proposition?.publicId}</h1>
    {!!error && <div className='alert alert-danger'>
      {error}
    </div>}
    <h2>Status {proposition?.pushStatus}</h2>
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center bg-dark text-white">
        Push Reservation(s)
      </div>
      <div className="card-body">
        <table className="table table-striped table-bordered">
          <thead>
            <tr className='text-white bg-dark'>
              <td className='text-white bg-secondary'>Type</td>
              <td className='text-white bg-secondary'>Status</td>
            </tr>
          </thead>
          <tbody>
            {propositionRow?.roomList?.map((room, index) => {
              const sameConditions = room.sameConditions

              if (sameConditions) {

                const isPending = !room.pmsId
                const isPushed = !!room.pmsId

                return <tr key={`room_${index}`} >
                  <td>Reservation</td>
                  <td>
                    {isPushed && <>
                      <span className='badge badge-success ml-1'>
                        OK
                      </span>
                      <span className='badge badge-info ml-1'>#{room?.pmsId}</span>
                    </>}
                    {isPending && <span className='badge badge-sm badge-warning'>
                      En Attente
                    </span>}
                  </td>
                </tr>

              } else {

                return room?.itemList?.map((item, idx) => {

                  const isPending = !item.pmsId
                  const isPushed = !!item.pmsId

                  return <tr key={`room_${index}_${idx}`} >
                    <td>Reservation</td>
                    <td>
                      {isPushed && <>
                        <span className='badge badge-success ml-1'>
                          OK
                        </span>
                        <span className='badge badge-info ml-1'>#{item?.pmsId}</span>
                      </>}
                      {isPending && <span className='badge badge-sm badge-warning'>
                        En Attente
                      </span>}
                    </td>
                  </tr>
                })
              }
            })}
          </tbody>
        </table>
      </div>
    </div>
    {hasDiscounts && <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center bg-dark text-white">
        Push Reductions(s)
      </div>
      <div className="card-body">
        <table className="table table-striped table-bordered">
          <thead>
            <tr className='text-white bg-secondary'>
              <td className='text-white bg-secondary'>Type</td>
              <td className='text-white bg-secondary'>Status</td>
            </tr>
          </thead>
          <tbody>
            {propositionRow?.roomList?.map((room, index) => {
              const sameConditions = room.sameConditions

              if (sameConditions) {

                const isPending = !room.discountPushed
                const isPushed = !!room.discountPushed

                return <tr key={`room_${index}`}>
                  <td>Reservation #{room?.pmsId}</td>
                  <td>
                    {isPushed && <>
                      <span className='badge badge-success ml-1'>
                        OK
                      </span>
                    </>}
                    {isPending && <span className='badge badge-sm badge-warning'>
                      En Attente
                    </span>}
                  </td>
                </tr>

              } else {

                return room?.itemList?.map((item, idx) => {

                  const isPending = !item.discountPushed
                  const isPushed = !!item.discountPushed

                  return <tr key={`room_${index}_${idx}`}>
                    <td>Reservation {idx + 1}</td>
                    <td>
                      {isPushed && <>
                        <span className='badge badge-success ml-1'>
                          OK
                        </span>
                        <span className='badge badge-info ml-1'>#{item?.pmsId}</span>
                      </>}
                      {isPending && <span className='badge badge-sm badge-warning'>
                        En Attente
                      </span>}
                    </td>
                    {/* {isPushed && <span className='icon-check'></span>}
                    {isPending && <span className='icon-pending'></span>}
                    <span className='ml-2'>{idx + 1}.</span>
                    <span className="ml-2">Reservation {item?.pmsId ? `1${item?.pmsId}` : ``}</span> */}
                  </tr>
                })
              }
            })}
          </tbody>
        </table>
      </div>
    </div>}
    {hasOptionsToPush && <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center text-white bg-dark">
        Push Options(s)
      </div>
      <div className="card-body">
        <table className="table table-striped table-bordered">
          {/* <thead>
            <tr className='text-white bg-secondary'>
              <td className='text-white bg-secondary'>Type</td>
              <td className='text-white bg-secondary'>Status</td>
            </tr>
          </thead> */}
          <tbody>
            {propositionRow?.roomList?.map((room, index) => {

              return <tr key={`option_tr_${index}} `}>
                <td>Resa {index + 1}</td>
                <td>
                  <table className='table table-striped table-bordered'>
                    <tbody>
                      {room?.optionList?.map((option: PropositionRowRoomOption, idx) => {
                        const isPending = !option.optionPushed
                        const isPushed = !!option.optionPushed

                        return <tr key={`option_${index}_${idx}`} className=''>

                          <td>Option {option?.Option?.labelFr}.</td>
                          <td>
                            {isPushed && <>
                              <span className='badge badge-success ml-1'>
                                OK
                              </span>
                              {/* <span className='badge badge-info ml-1'>#{item?.pmsId}</span> */}
                            </>}
                            {isPending && <span className='badge badge-sm badge-warning'>
                              En Attente
                            </span>}
                          </td>
                          {/* 
                      {isPushed && <span className='icon-check'></span>}
                      {isPending && <span className='icon-pending'></span>}
                      <span className='ml-2'>{idx + 1}.</span>
                      <span className="ml-2">Option {option?.Option?.labelFr}</span> */}
                        </tr>
                      })}
                    </tbody>
                  </table>
                </td>

              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>}
    {mustConfirm && <div className="card">
      <div className="card-header d-flex align-items-center justify-content-center text-white bg-dark">
        Push Statut Confirmé
      </div>
      <div className="card-body">
        <table className="table table-striped table-bordered">
          <thead>
            <tr className='text-white bg-secondary'>
              <td className='text-white bg-secondary'>Type</td>
              <td className='text-white bg-secondary'>Status</td>
            </tr>
          </thead>
          <tbody>
            {propositionRow?.roomList?.map((room, index) => {

              // const roomPushState = pushState.roomList[index]
              const sameConditions = room.sameConditions

              if (sameConditions) {

                const isPending = !room.confirmedPush
                const isPushed = !!room.confirmedPush

                return <tr key={`room_${index}_push`}>
                  <td>Reservation #{index}</td>
                  <td>
                    {isPushed && <>
                      <span className='badge badge-success ml-1'>
                        OK
                      </span>
                    </>}
                    {isPending && <span className='badge badge-sm badge-warning'>
                      En Attente
                    </span>}
                  </td>
                  {/* {isPushed && <span className='icon-check'></span>}
                  {isPending && <span className='icon-pending'></span>}
                  <span className=''>Reservation #{room?.pmsId}</span> */}
                </tr>

              } else {

                return room?.itemList?.map((item, idx) => {

                  // const itemPushState = roomPushState.itemList[idx]
                  const isPending = !item.confirmedPush
                  const isPushed = !!item.confirmedPush

                  return <tr key={`room_${index}_push_${idx}`} className=''>
                    <td>Reservation {idx + 1}</td>
                    <td>
                      {isPushed && <>
                        <span className='badge badge-success ml-1'>
                          OK
                        </span>
                        {/* <span className='badge badge-info ml-1'>#{item?.pmsId}</span> */}
                      </>}
                      {isPending && <span className='badge badge-sm badge-warning'>
                        En Attente
                      </span>}
                    </td>
                    {/* {isPushed && <span className='icon-check'></span>}
                    {isPending && <span className='icon-pending'></span>}
                    <span className="ml-3">Reservation #{item?.pmsId}</span> */}
                  </tr>
                })
              }
            })}
          </tbody>
        </table>
      </div>
    </div>}
    <div className='mt-2'>
      {canPush && <button disabled={!!loading} className='btn btn-sm btn-dark' onClick={() => onPush(proposition?.id)}>
        {loading && <span className='icon-loading mr-2'></span>} Pusher
      </button>}
    </div>
  </div>

}