export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type Query = {
  __typename?: 'Query';
  userConfigList?: Maybe<Array<UserConfig>>;
  userConfigGet?: Maybe<UserConfig>;
  userProfile?: Maybe<User>;
  authenticated: Scalars['Boolean'];
  userList: Array<User>;
  userGet: User;
  userListByUser: Array<User>;
  userRoleList: Array<Scalars['String']>;
  fileGet?: Maybe<File>;
  fileListByHotelId?: Maybe<Array<File>>;
  fileEncryptedGet?: Maybe<FileEncrypted>;
  mailMessageGet?: Maybe<MailMessage>;
  mailMessageList?: Maybe<Array<MailMessage>>;
  notificationList?: Maybe<Array<Notification>>;
  corpList?: Maybe<Array<Corp>>;
  corpGet?: Maybe<Corp>;
  templateList: Array<Template>;
  templateListByUser: Array<Template>;
  templateGet: Template;
  newsList: NewsListResult;
  newsGet: News;
  accounItemListLabels?: Maybe<Array<Scalars['JSONObject']>>;
  customerGet?: Maybe<Customer>;
  customerSearchByText?: Maybe<Array<Customer>>;
  customerGetByPhoneNumber?: Maybe<Customer>;
  customerCount?: Maybe<Scalars['Float']>;
  customerList?: Maybe<CustomerListResult>;
  customerEmailGet?: Maybe<CustomerEmail>;
  customerEmailListAllDomain?: Maybe<Array<Scalars['String']>>;
  customerPhoneGet?: Maybe<CustomerPhone>;
  customerAddressGet?: Maybe<CustomerAddress>;
  search?: Maybe<Array<SearchResult>>;
  segmentationCount: Scalars['Float'];
  segmentationList: Array<Segmentation>;
  segmentationGet: Segmentation;
  segmentationEmailMemberListBySegmentationId?: Maybe<SegmentationEmailMemberCursorResult>;
  sendinblueListList: Scalars['JSONObject'];
  targetGet?: Maybe<Target>;
  targetIsAgentAvailable?: Maybe<Scalars['Boolean']>;
  targetPing?: Maybe<Target>;
  channelGet?: Maybe<Channel>;
  phoneConfigGetByHotelId?: Maybe<PhoneConfig>;
  phoneAgentConfigGet?: Maybe<PhoneAgentConfig>;
  orderListAll: Array<Scalars['JSONObject']>;
  reservationList: Array<Reservation>;
  reservationListForCheckin: Array<Reservation>;
  reservationListEvent: Array<Event>;
  reservationGetByFilters: ReservationListResult;
  reservationCount: Scalars['Float'];
  reservationGet?: Maybe<Reservation>;
  reservationListByCustomerId?: Maybe<Array<Reservation>>;
  reservationGetByIdAndContactIdAndHotelId?: Maybe<Reservation>;
  reservationSearchByPmsId?: Maybe<Array<Reservation>>;
  reservationGroupList?: Maybe<ReservationGroupListResult>;
  reservationGroupGet?: Maybe<ReservationGroup>;
  priceRateListByCategory?: Maybe<Array<PriceRate>>;
  pricePackageBlacklistList: Array<ReservationPricePackageBlacklist>;
  pricePackageBlacklistListByHotel: Array<ReservationPricePackageBlacklist>;
  roomGet: Room;
  roomTypeList: Array<RoomType>;
  roomTypeGet: RoomType;
  propositionGetAvailibilities?: Maybe<Array<RoomAvailibility>>;
  propositionGet?: Maybe<PropositionUpdateResult>;
  propositionGetByIdAndPublicId?: Maybe<Proposition>;
  propositionList?: Maybe<PropositionCursorResult>;
  propositionStatusList?: Maybe<Array<Scalars['String']>>;
  propositionRowPaymentConditionList: Array<Scalars['String']>;
  propositionComputeTotal?: Maybe<PropositionRowTotal>;
  contactGetLastUpdated: Array<Contact>;
  contactGet: Contact;
  contactSearchByText: Array<Contact>;
  contactCountryList: Array<Country>;
  contactDedupList?: Maybe<ContactDedupListResult>;
  priceRateCancelConditionItemValueList: Array<Scalars['String']>;
  priceRateCancelConditionList: Array<PriceRateCancelCondition>;
  priceRateCancelConditionTest: PriceRateCancelConditionItemValueEnum | `${PriceRateCancelConditionItemValueEnum}`;
  priceRateGet?: Maybe<PriceRate>;
  hotelList?: Maybe<Array<Hotel>>;
  hotelListByCorpId?: Maybe<Array<Hotel>>;
  hotelGet?: Maybe<Hotel>;
  hotelConfTypeList?: Maybe<Array<Scalars['String']>>;
  optionList?: Maybe<Array<Option>>;
  optionSearch?: Maybe<Array<Option>>;
  optionGet?: Maybe<Option>;
  confirmationStatusList: Array<Scalars['String']>;
  confirmationList: ConfirmationListResult;
  confirmationGet: Confirmation;
  confirmationGetByIdAndPublicId?: Maybe<Confirmation>;
  confirmationListEvent?: Maybe<Array<Event>>;
  confirmationQuestionGetByConfirmationId?: Maybe<ConfirmationQuestion>;
  preStayListByHotelIds?: Maybe<Array<PreStay>>;
  preStayListByCursorAndInput?: Maybe<PreStayListResult>;
  preStayGet?: Maybe<PreStay>;
  preStayGetByIdAndPublicId?: Maybe<PreStay>;
  preStayGetEvent?: Maybe<Event>;
  preStayListEvent?: Maybe<Array<Event>>;
  postStayListByHotelIds?: Maybe<Array<PostStay>>;
  postStayListByCursorAndInput?: Maybe<PostStayListResult>;
  postStayGet?: Maybe<PostStay>;
  postStayGetByIdAndPublicId?: Maybe<PostStay>;
  postStayConfigGet?: Maybe<PostStayConfig>;
  postStayAnswerStatsGetByQuestionIdWithFilters?: Maybe<PostStayAnswerStats>;
  postStayAnswerStatsGetAvailableYears?: Maybe<Array<Scalars['String']>>;
  postStayAnswerStatsGetAvailableMonths?: Maybe<Array<Scalars['String']>>;
  postStayAnswerStatsGetAvailableWeeks?: Maybe<Array<Scalars['String']>>;
  serviceListByHotelId?: Maybe<Array<Service>>;
  serviceListByConfirmationId?: Maybe<Array<Service>>;
  seasonalityGet?: Maybe<Seasonality>;
  seasonalityList?: Maybe<Array<Seasonality>>;
  checkinList?: Maybe<Array<Checkin>>;
  checkinGet?: Maybe<Checkin>;
  eventList: EventListResult;
  eventListBySourceId: Array<Event>;
  eventGet: Event;
  eventListByPlanning?: Maybe<Array<Event>>;
  planningWeeklyGetDateByInterval?: Maybe<Array<Scalars['DateTime']>>;
  planningList: Array<Planning>;
  planningGet: Planning;
  countryList: Array<Scalars['JSONObject']>;
  productGetAll: Array<Scalars['JSONObject']>;
  reservationGetByPayload: Array<Scalars['JSONObject']>;
  reservationGetUpdatedToday: Array<Scalars['JSONObject']>;
  reservationGetByNumber: Array<Scalars['JSONObject']>;
  reservationBillGetByPayload: Scalars['JSONObject'];
  hotsoftAccessTokenGetByHotelId?: Maybe<Scalars['String']>;
  reservationFoliosGetByPayload: Scalars['JSONObject'];
  priceRateGetByPayload: Array<Scalars['JSONObject']>;
  dateConvertTimestamp: Scalars['String'];
  dateConvertDate: Scalars['String'];
  dateConvertPmsStringToDate: Scalars['DateTime'];
  reservationCheckOutByPayload: Array<Scalars['JSONObject']>;
  marketCodeGetByPayload: Array<Scalars['JSONObject']>;
  contactGetByPayload: Array<Scalars['JSONObject']>;
  roomGetByPayload: Scalars['JSONObject'];
  roomListStatus: Array<Scalars['JSONObject']>;
  hotsoftRoomList: Array<Scalars['JSONObject']>;
  paymentGet: Payment;
  paymentList: PaymentListResult;
  paymentGetByPublicIdAndPublicSalt: Payment;
  paymentGetById: Payment;
  paymentCaptureTypeList: Array<Scalars['String']>;
  paymentStatusList: Array<Scalars['String']>;
  paymentReaderTest?: Maybe<Scalars['JSONObject']>;
  tokenGet: Token;
  tokenList: TokenListResult;
  tokenGetByPublicIdAndPublicSalt: Token;
  chargeGet?: Maybe<Charge>;
  terminalList?: Maybe<Array<Terminal>>;
  terminalGet?: Maybe<Terminal>;
  protelContactGenerateSQLUpdateFromImportId?: Maybe<Scalars['String']>;
  protelReservationGenerateSQLUpdateFromImportId?: Maybe<Scalars['String']>;
  vegaContactGenerateSQLUpdateFromImportId?: Maybe<Scalars['String']>;
  cameraListByUser?: Maybe<Array<Camera>>;
  cameraGet?: Maybe<Camera>;
  runnerGet?: Maybe<Runner>;
  runnerSegmentList?: Maybe<Array<Scalars['String']>>;
  hello: Scalars['String'];
  customerMetricGenerateFromCustomer?: Maybe<CustomerMetric>;
};


export type QueryuserConfigGetArgs = {
  id: Scalars['String'];
};


export type QueryuserGetArgs = {
  id: Scalars['String'];
};


export type QueryfileGetArgs = {
  id: Scalars['String'];
};


export type QueryfileListByHotelIdArgs = {
  hotelId: Scalars['String'];
};


export type QueryfileEncryptedGetArgs = {
  id: Scalars['String'];
};


export type QuerymailMessageGetArgs = {
  id: Scalars['String'];
};


export type QuerycorpGetArgs = {
  id: Scalars['String'];
};


export type QuerytemplateListArgs = {
  hotelId: Scalars['String'];
};


export type QuerytemplateGetArgs = {
  id: Scalars['String'];
};


export type QuerynewsListArgs = {
  cursor: CursorInput;
};


export type QuerynewsGetArgs = {
  id: Scalars['String'];
};


export type QuerycustomerGetArgs = {
  id: Scalars['String'];
};


export type QuerycustomerSearchByTextArgs = {
  text: Scalars['String'];
};


export type QuerycustomerGetByPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type QuerycustomerListArgs = {
  cursor: CursorInput;
  order?: InputMaybe<OrderInput>;
  input?: InputMaybe<CustomerListInput>;
};


export type QuerycustomerEmailGetArgs = {
  id: Scalars['String'];
};


export type QuerycustomerPhoneGetArgs = {
  id: Scalars['String'];
};


export type QuerycustomerAddressGetArgs = {
  id: Scalars['String'];
};


export type QuerysearchArgs = {
  text: Scalars['String'];
};


export type QuerysegmentationCountArgs = {
  input: SegmentationCountInput;
};


export type QuerysegmentationGetArgs = {
  id: Scalars['String'];
};


export type QuerysegmentationEmailMemberListBySegmentationIdArgs = {
  cursor: CursorInput;
  segmentationId: Scalars['String'];
};


export type QuerytargetGetArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QuerytargetIsAgentAvailableArgs = {
  hotelId?: InputMaybe<Scalars['String']>;
};


export type QuerytargetPingArgs = {
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerychannelGetArgs = {
  channelId: Scalars['String'];
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryphoneConfigGetByHotelIdArgs = {
  hotelId: Scalars['String'];
};


export type QueryreservationListArgs = {
  order: OrderInput;
  cursor: CursorInput;
};


export type QueryreservationListForCheckinArgs = {
  input: ReservationListForCheckinInput;
};


export type QueryreservationListEventArgs = {
  reservationId: Scalars['String'];
};


export type QueryreservationGetByFiltersArgs = {
  input: ReservationFilterInput;
  order: OrderInput;
  cursor: CursorInput;
};


export type QueryreservationGetArgs = {
  id: Scalars['String'];
};


export type QueryreservationListByCustomerIdArgs = {
  customerId: Scalars['String'];
};


export type QueryreservationGetByIdAndContactIdAndHotelIdArgs = {
  hotelId: Scalars['String'];
  contactId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryreservationSearchByPmsIdArgs = {
  pmsId: Scalars['String'];
  hotelId: Scalars['String'];
};


export type QueryreservationGroupListArgs = {
  cursor: CursorInput;
  input: ReservationGroupListInput;
};


export type QueryreservationGroupGetArgs = {
  id: Scalars['String'];
};


export type QuerypriceRateListByCategoryArgs = {
  nbSpecial: Scalars['Float'];
  nbChildren: Scalars['Float'];
  nbAdults: Scalars['Float'];
  roomTypeId: Scalars['String'];
  date: Scalars['DateTime'];
  hotelId: Scalars['String'];
};


export type QuerypricePackageBlacklistListByHotelArgs = {
  hotelId: Scalars['String'];
};


export type QueryroomGetArgs = {
  id: Scalars['String'];
};


export type QueryroomTypeListArgs = {
  hotelId: Scalars['String'];
};


export type QueryroomTypeGetArgs = {
  id: Scalars['String'];
};


export type QuerypropositionGetAvailibilitiesArgs = {
  input: PropositionAvailibilityInput;
};


export type QuerypropositionGetArgs = {
  id: Scalars['String'];
};


export type QuerypropositionGetByIdAndPublicIdArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerypropositionListArgs = {
  input: PropositionListInput;
  cursor: CursorInput;
};


export type QuerypropositionComputeTotalArgs = {
  input: PropositionRowInput;
};


export type QuerycontactGetArgs = {
  id: Scalars['String'];
};


export type QuerycontactSearchByTextArgs = {
  text: Scalars['String'];
  hotelId: Scalars['String'];
};


export type QuerycontactDedupListArgs = {
  cursor: CursorInput;
  input: ContactDedupListInput;
};


export type QuerypriceRateCancelConditionListArgs = {
  hotelId: Scalars['String'];
};


export type QuerypriceRateGetArgs = {
  id: Scalars['String'];
};


export type QueryhotelListByCorpIdArgs = {
  corpId: Scalars['String'];
};


export type QueryhotelGetArgs = {
  id: Scalars['String'];
};


export type QueryoptionListArgs = {
  hotelId: Scalars['String'];
};


export type QueryoptionSearchArgs = {
  text: Scalars['String'];
  hotelId: Scalars['String'];
};


export type QueryoptionGetArgs = {
  id: Scalars['String'];
};


export type QueryconfirmationListArgs = {
  input: ConfirmationListInput;
  cursor: CursorInput;
};


export type QueryconfirmationGetArgs = {
  id: Scalars['String'];
};


export type QueryconfirmationGetByIdAndPublicIdArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryconfirmationListEventArgs = {
  id: Scalars['String'];
};


export type QueryconfirmationQuestionGetByConfirmationIdArgs = {
  confirmationId: Scalars['String'];
};


export type QuerypreStayListByHotelIdsArgs = {
  hotelIdList: Array<Scalars['String']>;
};


export type QuerypreStayListByCursorAndInputArgs = {
  cursor: CursorInput;
  input: PreStayListInput;
};


export type QuerypreStayGetArgs = {
  id: Scalars['String'];
};


export type QuerypreStayGetByIdAndPublicIdArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerypreStayGetEventArgs = {
  eventId: Scalars['String'];
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerypreStayListEventArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerypostStayListByHotelIdsArgs = {
  hotelIdList: Array<Scalars['String']>;
};


export type QuerypostStayListByCursorAndInputArgs = {
  cursor: CursorInput;
  input: PostStayListInput;
};


export type QuerypostStayGetArgs = {
  id: Scalars['String'];
};


export type QuerypostStayGetByIdAndPublicIdArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerypostStayConfigGetArgs = {
  hotelId: Scalars['String'];
};


export type QuerypostStayAnswerStatsGetByQuestionIdWithFiltersArgs = {
  questionId: Scalars['String'];
  input: PostStayAnswerStatsFilterInput;
};


export type QuerypostStayAnswerStatsGetAvailableYearsArgs = {
  hotelId: Scalars['String'];
};


export type QuerypostStayAnswerStatsGetAvailableMonthsArgs = {
  year: Scalars['Float'];
  hotelId: Scalars['String'];
};


export type QuerypostStayAnswerStatsGetAvailableWeeksArgs = {
  year: Scalars['Float'];
  hotelId: Scalars['String'];
};


export type QueryserviceListByHotelIdArgs = {
  hotelId: Scalars['String'];
};


export type QueryserviceListByConfirmationIdArgs = {
  confirmationId: Scalars['String'];
};


export type QueryseasonalityGetArgs = {
  id: Scalars['String'];
};


export type QueryseasonalityListArgs = {
  hotelId: Scalars['String'];
};


export type QuerycheckinListArgs = {
  input: CheckinListInput;
};


export type QuerycheckinGetArgs = {
  id: Scalars['String'];
};


export type QueryeventListArgs = {
  cursor: CursorInput;
  input: EventListInput;
};


export type QueryeventListBySourceIdArgs = {
  sourceId: Scalars['String'];
};


export type QueryeventGetArgs = {
  id: Scalars['String'];
};


export type QueryeventListByPlanningArgs = {
  input: PlanningViewInput;
  id: Scalars['String'];
};


export type QueryplanningWeeklyGetDateByIntervalArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  input: PlanningWeeklyInput;
};


export type QueryplanningGetArgs = {
  id: Scalars['String'];
};


export type QuerycountryListArgs = {
  hotelId: Scalars['String'];
};


export type QueryproductGetAllArgs = {
  hotelId: Scalars['String'];
};


export type QueryreservationGetByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QueryreservationGetUpdatedTodayArgs = {
  hotelId: Scalars['String'];
};


export type QueryreservationGetByNumberArgs = {
  number: Scalars['String'];
  hotelId: Scalars['String'];
};


export type QueryreservationBillGetByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QueryhotsoftAccessTokenGetByHotelIdArgs = {
  hotelId: Scalars['String'];
};


export type QueryreservationFoliosGetByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QuerypriceRateGetByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QuerydateConvertTimestampArgs = {
  timestamp: Scalars['String'];
};


export type QuerydateConvertDateArgs = {
  date: Scalars['String'];
};


export type QuerydateConvertPmsStringToDateArgs = {
  date: Scalars['String'];
};


export type QueryreservationCheckOutByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QuerymarketCodeGetByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QuerycontactGetByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QueryroomGetByPayloadArgs = {
  payload: Scalars['JSONObject'];
  hotelId: Scalars['String'];
};


export type QueryroomListStatusArgs = {
  hotelId: Scalars['String'];
};


export type QueryhotsoftRoomListArgs = {
  hotelId: Scalars['String'];
};


export type QuerypaymentGetArgs = {
  id: Scalars['String'];
};


export type QuerypaymentListArgs = {
  input: PaymentListInput;
  cursor: CursorInput;
};


export type QuerypaymentGetByPublicIdAndPublicSaltArgs = {
  salt: Scalars['String'];
  id: Scalars['String'];
};


export type QuerypaymentGetByIdArgs = {
  id: Scalars['String'];
};


export type QuerypaymentReaderTestArgs = {
  id: Scalars['String'];
};


export type QuerytokenGetArgs = {
  id: Scalars['String'];
};


export type QuerytokenListArgs = {
  input: TokenListInput;
  cursor: CursorInput;
};


export type QuerytokenGetByPublicIdAndPublicSaltArgs = {
  salt: Scalars['String'];
  id: Scalars['String'];
};


export type QuerychargeGetArgs = {
  id: Scalars['String'];
};


export type QueryterminalListArgs = {
  hotelId: Scalars['String'];
};


export type QueryterminalGetArgs = {
  id: Scalars['String'];
};


export type QueryprotelContactGenerateSQLUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type QueryprotelReservationGenerateSQLUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type QueryvegaContactGenerateSQLUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type QuerycameraGetArgs = {
  id: Scalars['String'];
};


export type QueryrunnerGetArgs = {
  id: Scalars['String'];
};


export type QueryrunnerSegmentListArgs = {
  depth?: InputMaybe<Scalars['String']>;
  date: Scalars['DateTime'];
  id: Scalars['String'];
};


export type QuerycustomerMetricGenerateFromCustomerArgs = {
  customerId: Scalars['String'];
};

export type UserConfig = {
  __typename?: 'UserConfig';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  datas?: Maybe<UserConfigData>;
};

export type UserConfigData = {
  __typename?: 'UserConfigData';
  title?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  fontFamily?: Maybe<Scalars['String']>;
  themeMainColor?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  configId?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRoleEnum | `${UserRoleEnum}`>>;
  hotels?: Maybe<Array<Scalars['String']>>;
  impersonatedUser?: Maybe<Scalars['String']>;
  impersonator?: Maybe<Scalars['String']>;
  isManager?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isTechnical?: Maybe<Scalars['Boolean']>;
  isWebmaster?: Maybe<Scalars['Boolean']>;
  isBilling?: Maybe<Scalars['Boolean']>;
  isUser?: Maybe<Scalars['Boolean']>;
  notificationListToMail?: Maybe<Array<NotificationTypeEnum | `${NotificationTypeEnum}`>>;
  Hotel: Array<Hotel>;
  PhoneAgentConfig?: Maybe<PhoneAgentConfig>;
  PhoneSipConfig?: Maybe<PhoneSipConfig>;
  UserConfig?: Maybe<UserConfig>;
  Corp?: Maybe<Corp>;
};

export enum UserRoleEnum {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_CONFIG = 'ROLE_CONFIG',
  ROLE_CONFIG_FILE = 'ROLE_CONFIG_FILE',
  ROLE_CONFIG_GENERAL = 'ROLE_CONFIG_GENERAL',
  ROLE_CONFIG_CONFIRMATION = 'ROLE_CONFIG_CONFIRMATION',
  ROLE_CONFIG_PROPOSITION = 'ROLE_CONFIG_PROPOSITION',
  ROLE_CONFIG_OPTIONS = 'ROLE_CONFIG_OPTIONS',
  ROLE_CONFIG_RATE_CODE = 'ROLE_CONFIG_RATE_CODE',
  ROLE_CONFIG_ROOM = 'ROLE_CONFIG_ROOM',
  ROLE_CONFIG_ROOM_TYPE = 'ROLE_CONFIG_ROOM_TYPE',
  ROLE_CONFIG_STAY = 'ROLE_CONFIG_STAY',
  ROLE_CONFIG_POST_STAY = 'ROLE_CONFIG_POST_STAY',
  ROLE_CONFIG_PRE_STAY = 'ROLE_CONFIG_PRE_STAY',
  ROLE_CONFIG_SERVICE = 'ROLE_CONFIG_SERVICE',
  ROLE_CONFIG_BILLING = 'ROLE_CONFIG_BILLING',
  ROLE_CONFIG_CANCEL_CONDITIONS = 'ROLE_CONFIG_CANCEL_CONDITIONS',
  ROLE_USER = 'ROLE_USER',
  ROLE_CRM = 'ROLE_CRM',
  ROLE_CHAT = 'ROLE_CHAT',
  ROLE_PMS = 'ROLE_PMS',
  ROLE_PMS_CHECKIN = 'ROLE_PMS_CHECKIN',
  ROLE_PMS_RESERVATION = 'ROLE_PMS_RESERVATION',
  ROLE_PMS_STAY_POST = 'ROLE_PMS_STAY_POST',
  ROLE_PMS_STAY_PRE = 'ROLE_PMS_STAY_PRE',
  ROLE_PMS_PROPOSITION_PUSH = 'ROLE_PMS_PROPOSITION_PUSH',
  ROLE_BILLING = 'ROLE_BILLING',
  ROLE_BILLING_EDIT = 'ROLE_BILLING_EDIT',
  ROLE_BILLING_TOKEN = 'ROLE_BILLING_TOKEN',
  ROLE_BILLING_PRE_AUTH = 'ROLE_BILLING_PRE_AUTH',
  ROLE_BILLING_PAYMENT = 'ROLE_BILLING_PAYMENT',
  ROLE_BILLING_READ = 'ROLE_BILLING_READ',
  ROLE_BILLING_EMAIL = 'ROLE_BILLING_EMAIL',
  ROLE_BILLING_REFUND = 'ROLE_BILLING_REFUND',
  ROLE_PLANNING = 'ROLE_PLANNING',
  ROLE_PHONE = 'ROLE_PHONE',
  ROLE_VIDEO_CONFIG = 'ROLE_VIDEO_CONFIG',
  ROLE_VIDEO = 'ROLE_VIDEO',
  ROLE_NETWORK_VIEW = 'ROLE_NETWORK_VIEW',
  ROLE_NETWORK_VIEW_WRITE = 'ROLE_NETWORK_VIEW_WRITE',
  ROLE_DEBUG = 'ROLE_DEBUG'
}

export enum NotificationTypeEnum {
  TYPE_SERVICE_EVENT_MANAGER_NEW = 'TYPE_SERVICE_EVENT_MANAGER_NEW',
  TYPE_TOKEN_DONE = 'TYPE_TOKEN_DONE',
  TYPE_PAYMENT_DONE = 'TYPE_PAYMENT_DONE',
  TYPE_POSTSTAY_ALERT = 'TYPE_POSTSTAY_ALERT',
  TYPE_PROPOSITION_ACCEPTED = 'TYPE_PROPOSITION_ACCEPTED',
  TYPE_PRESTAY_UPDATED = 'TYPE_PRESTAY_UPDATED',
  TYPE_PHONE_CALL_MISSED = 'TYPE_PHONE_CALL_MISSED'
}

export type Hotel = {
  __typename?: 'Hotel';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  activeFetcher?: Maybe<Scalars['Boolean']>;
  lastFetch?: Maybe<Scalars['DateTime']>;
  lastFetchError?: Maybe<Scalars['String']>;
  stayTax?: Maybe<Scalars['Float']>;
  postStayTemplateId?: Maybe<Scalars['String']>;
  preStayTemplateId?: Maybe<Scalars['String']>;
  emailSender?: Maybe<MailMessageAddress>;
  logoUrl?: Maybe<Scalars['String']>;
  logoMonoUrl?: Maybe<Scalars['String']>;
  backgroundUrl?: Maybe<Scalars['String']>;
  cgvUrlFr?: Maybe<Scalars['String']>;
  cgvUrlEn?: Maybe<Scalars['String']>;
  cgvUrlEnFileId?: Maybe<Scalars['String']>;
  cgvUrlFrFileId?: Maybe<Scalars['String']>;
  themeColor?: Maybe<Scalars['String']>;
  backgroundThemeColor?: Maybe<Scalars['String']>;
  breakfastStartHour?: Maybe<Scalars['String']>;
  breakfastEndHour?: Maybe<Scalars['String']>;
  checkInHour?: Maybe<Scalars['String']>;
  checkOutHour?: Maybe<Scalars['String']>;
  clic2CallScenarioId?: Maybe<Scalars['String']>;
  notificationDest?: Maybe<Array<Scalars['String']>>;
  phoneConfigId?: Maybe<Scalars['String']>;
  displayRoomPictureDisclosure?: Maybe<Scalars['Boolean']>;
  displayRoomCategoryInDetails?: Maybe<Scalars['Boolean']>;
  RoomType?: Maybe<Array<RoomType>>;
  Room?: Maybe<Array<Room>>;
  Option?: Maybe<Array<Option>>;
  PriceRateType?: Maybe<Array<PriceRateType>>;
  PriceRateCancelCondition?: Maybe<Array<PriceRateCancelCondition>>;
  BillingConfig?: Maybe<BillingConfig>;
  PostStayConfig?: Maybe<PostStayConfig>;
  preStayConfig?: Maybe<PreStayConfig>;
  ServiceList?: Maybe<Array<Service>>;
  Seasonality?: Maybe<Array<Seasonality>>;
  CGVFrFile?: Maybe<File>;
  CGVEnFile?: Maybe<File>;
  PhoneConfig?: Maybe<PhoneConfig>;
  NetworkConfig?: Maybe<NetworkConfig>;
  PmsConfig?: Maybe<PmsConfig>;
  GoogleConfig?: Maybe<GoogleConfig>;
  TripAdvisorConfig?: Maybe<TripAdvisorConfig>;
  ConfirmationConfig?: Maybe<ConfirmationConfig>;
  PropositionConfig?: Maybe<PropositionConfig>;
  HotelToken?: Maybe<Array<HotelToken>>;
  VideoConfig?: Maybe<VideoConfig>;
};

export type MailMessageAddress = {
  __typename?: 'MailMessageAddress';
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type RoomType = {
  __typename?: 'RoomType';
  id: Scalars['ID'];
  hotelId: Scalars['String'];
  type: Scalars['String'];
  labelFr?: Maybe<Scalars['String']>;
  canBeSelectedInProposition?: Maybe<Scalars['Boolean']>;
  labelEn?: Maybe<Scalars['String']>;
  descImgUrls?: Maybe<Array<Scalars['String']>>;
  descCapacityFr?: Maybe<Scalars['String']>;
  descTextFr?: Maybe<Scalars['String']>;
  descCapacityEn?: Maybe<Scalars['String']>;
  descTextEn?: Maybe<Scalars['String']>;
};

export type Room = {
  __typename?: 'Room';
  id: Scalars['ID'];
  hotelId: Scalars['String'];
  roomTypeId: Scalars['String'];
  floor?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  nbBeds?: Maybe<Scalars['Float']>;
  RoomType?: Maybe<RoomType>;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['String'];
  hotelId: Scalars['String'];
  pmsId: Scalars['String'];
  amountIncVat?: Maybe<Scalars['Float']>;
  amountExcVat?: Maybe<Scalars['Float']>;
  labelFr?: Maybe<Scalars['String']>;
  descFr?: Maybe<Scalars['String']>;
  labelEn?: Maybe<Scalars['String']>;
  descEn?: Maybe<Scalars['String']>;
  picto?: Maybe<Scalars['String']>;
  fileId?: Maybe<Scalars['String']>;
  canBeDisplayedInConfirmation?: Maybe<Scalars['Boolean']>;
  canBeSelectedInProposition?: Maybe<Scalars['Boolean']>;
  canBeSelectedInPreStay?: Maybe<Scalars['Boolean']>;
  isBreakfast?: Maybe<Scalars['Boolean']>;
  isSpa?: Maybe<Scalars['Boolean']>;
  isRestaurant?: Maybe<Scalars['Boolean']>;
  isRestaurant2?: Maybe<Scalars['Boolean']>;
  isPerNight?: Maybe<Scalars['Boolean']>;
  isPerAdult?: Maybe<Scalars['Boolean']>;
  isPerChildren?: Maybe<Scalars['Boolean']>;
  isPerSpecial?: Maybe<Scalars['Boolean']>;
  seasonalityExclude?: Maybe<Array<Scalars['String']>>;
  File?: Maybe<File>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PriceRateType = {
  __typename?: 'PriceRateType';
  id: Scalars['String'];
  hotelId: Scalars['String'];
  code: Scalars['String'];
  isBreakfastIncluded: Scalars['Boolean'];
  isRefundable: Scalars['Boolean'];
  isGiftBox: Scalars['Boolean'];
  isIncludedInConfirmationTotal: Scalars['Boolean'];
  isGroup?: Maybe<Scalars['Boolean']>;
  isFree?: Maybe<Scalars['Boolean']>;
  hasSpa?: Maybe<Scalars['Boolean']>;
  hasFood?: Maybe<Scalars['Boolean']>;
  hasDrink?: Maybe<Scalars['Boolean']>;
  hasService?: Maybe<Scalars['Boolean']>;
  canBeSelectedInProposition?: Maybe<Scalars['Boolean']>;
  labelFr: Scalars['String'];
  labelEn: Scalars['String'];
  priceRateCancelConditionId?: Maybe<Scalars['String']>;
  conditionsFR?: Maybe<Scalars['String']>;
  conditionsEN?: Maybe<Scalars['String']>;
  PriceRateCancelCondition?: Maybe<PriceRateCancelCondition>;
};

export type PriceRateCancelCondition = {
  __typename?: 'PriceRateCancelCondition';
  id?: Maybe<Scalars['String']>;
  hotelId: Scalars['String'];
  label: Scalars['String'];
  type?: Maybe<PriceRateCancelConditionType | `${PriceRateCancelConditionType}`>;
  defaultValue?: Maybe<PriceRateCancelConditionItemValueEnum | `${PriceRateCancelConditionItemValueEnum}`>;
  conditionList?: Maybe<Array<PriceRateCancelConditionItem>>;
  functionText?: Maybe<Scalars['String']>;
};

export enum PriceRateCancelConditionType {
  TYPE_CLASSIC = 'TYPE_CLASSIC',
  TYPE_FUNCTION = 'TYPE_FUNCTION'
}

export enum PriceRateCancelConditionItemValueEnum {
  CONDITION_0 = 'CONDITION_0',
  CONDITION_10 = 'CONDITION_10',
  CONDITION_15 = 'CONDITION_15',
  CONDITION_20 = 'CONDITION_20',
  CONDITION_30 = 'CONDITION_30',
  CONDITION_50 = 'CONDITION_50',
  CONDITION_100 = 'CONDITION_100'
}

export type PriceRateCancelConditionItem = {
  __typename?: 'PriceRateCancelConditionItem';
  before: Scalars['Float'];
  value: PriceRateCancelConditionItemValueEnum | `${PriceRateCancelConditionItemValueEnum}`;
};

export type BillingConfig = {
  __typename?: 'BillingConfig';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  type?: Maybe<BillingConfigTypeEnum | `${BillingConfigTypeEnum}`>;
  templateId?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSONObject']>;
  StripeConfig?: Maybe<StripeConfig>;
  Terminal?: Maybe<Array<Terminal>>;
};

export enum BillingConfigTypeEnum {
  STRIPE = 'STRIPE',
  PAYTWEAK = 'PAYTWEAK'
}

export type StripeConfig = {
  __typename?: 'StripeConfig';
  id: Scalars['ID'];
  billingConfigId?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
  publishableKey?: Maybe<Scalars['String']>;
  webHookKey?: Maybe<Scalars['String']>;
};

export type Terminal = {
  __typename?: 'Terminal';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  type?: Maybe<TerminalTypeEnum | `${TerminalTypeEnum}`>;
  status?: Maybe<TerminalStatusEnum | `${TerminalStatusEnum}`>;
  currentActionType?: Maybe<TerminalActionType | `${TerminalActionType}`>;
  currentActionId?: Maybe<Scalars['String']>;
  currentActionObject?: Maybe<Scalars['JSONObject']>;
  StripeOptions?: Maybe<StripeTerminalOptions>;
};

export enum TerminalTypeEnum {
  TYPE_STRIPE = 'TYPE_STRIPE'
}

export enum TerminalStatusEnum {
  STATUS_IDLE = 'STATUS_IDLE',
  STATUS_PROCESSING = 'STATUS_PROCESSING'
}

export enum TerminalActionType {
  TYPE_TOKEN = 'TYPE_TOKEN'
}

export type StripeTerminalOptions = {
  __typename?: 'StripeTerminalOptions';
  id: Scalars['ID'];
  terminalId?: Maybe<Scalars['String']>;
  stripeTerminalId?: Maybe<Scalars['String']>;
};

export type PostStayConfig = {
  __typename?: 'PostStayConfig';
  id: Scalars['ID'];
  dest?: Maybe<Array<Scalars['String']>>;
  alertThreshold?: Maybe<Scalars['Float']>;
  hotelId?: Maybe<Scalars['String']>;
  postStayActive?: Maybe<Scalars['Boolean']>;
  demoMode?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  nbDaysAfterDeparture?: Maybe<Scalars['Float']>;
  sendHour?: Maybe<Scalars['Float']>;
  channel?: Maybe<Array<Maybe<Scalars['String']>>>;
  PostStayGroup?: Maybe<Array<PostStayGroup>>;
};

export type PostStayGroup = {
  __typename?: 'PostStayGroup';
  id: Scalars['ID'];
  postStayConfigId?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  labelFr?: Maybe<Scalars['String']>;
  descFr?: Maybe<Scalars['String']>;
  labelEn?: Maybe<Scalars['String']>;
  descEn?: Maybe<Scalars['String']>;
  itemList?: Maybe<Array<PostStayGroupItem>>;
  PostStayGroupItem?: Maybe<Array<PostStayGroupItem>>;
};

export type PostStayGroupItem = {
  __typename?: 'PostStayGroupItem';
  id: Scalars['ID'];
  priority?: Maybe<Scalars['Float']>;
  postStayGroupId?: Maybe<Scalars['String']>;
  type?: Maybe<PostStayGroupItemTypeEnum | `${PostStayGroupItemTypeEnum}`>;
  Separator?: Maybe<PostStayGroupSeparator>;
  Question?: Maybe<PostStayQuestion>;
};

export enum PostStayGroupItemTypeEnum {
  TYPE_QUESTION = 'TYPE_QUESTION',
  TYPE_SEPARATOR = 'TYPE_SEPARATOR'
}

export type PostStayGroupSeparator = {
  __typename?: 'PostStayGroupSeparator';
  id?: Maybe<Scalars['ID']>;
  postStayGroupItemId?: Maybe<Scalars['String']>;
  labelFr?: Maybe<Scalars['String']>;
  labelEn?: Maybe<Scalars['String']>;
};

export type PostStayQuestion = {
  __typename?: 'PostStayQuestion';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<PostStayQuestionTypeEnum | `${PostStayQuestionTypeEnum}`>;
  postStayGroupItemId?: Maybe<Scalars['String']>;
  conditionKey?: Maybe<Scalars['String']>;
  conditionValue?: Maybe<Scalars['String']>;
  alertValue?: Maybe<Scalars['String']>;
  choiceList?: Maybe<Array<Scalars['JSONObject']>>;
  name?: Maybe<Scalars['String']>;
  labelFr?: Maybe<Scalars['String']>;
  labelEn?: Maybe<Scalars['String']>;
  descFr?: Maybe<Scalars['String']>;
  descEn?: Maybe<Scalars['String']>;
  PostStayAnswerStats?: Maybe<PostStayAnswerStats>;
};

export enum PostStayQuestionTypeEnum {
  TYPE_STAR = 'TYPE_STAR',
  TYPE_TEXT = 'TYPE_TEXT',
  TYPE_TEXTAREA = 'TYPE_TEXTAREA',
  TYPE_CHOICE = 'TYPE_CHOICE',
  TYPE_CHOICE_RADIO = 'TYPE_CHOICE_RADIO',
  TYPE_BOOLEAN = 'TYPE_BOOLEAN'
}

export type PostStayAnswerStats = {
  __typename?: 'PostStayAnswerStats';
  id: Scalars['ID'];
  questionId?: Maybe<Scalars['String']>;
  dimensionType?: Maybe<StatsDimensionType | `${StatsDimensionType}`>;
  dimensionValue?: Maybe<Scalars['DateTime']>;
  choiceValue?: Maybe<Scalars['JSONObject']>;
  avgValue?: Maybe<Scalars['Float']>;
  nbValue?: Maybe<Scalars['Float']>;
  minValue?: Maybe<Scalars['Float']>;
  maxValue?: Maybe<Scalars['Float']>;
};

export enum StatsDimensionType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export type PreStayConfig = {
  __typename?: 'PreStayConfig';
  preStayGroup?: Maybe<Array<PreStayGroup>>;
};

export type PreStayGroup = {
  __typename?: 'PreStayGroup';
  id?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  labelFr?: Maybe<Scalars['String']>;
  descFr?: Maybe<Scalars['String']>;
  labelEn?: Maybe<Scalars['String']>;
  descEn?: Maybe<Scalars['String']>;
  questionList?: Maybe<Array<PreStayGroupQuestion>>;
};

export type PreStayGroupQuestion = {
  __typename?: 'PreStayGroupQuestion';
  labelFr?: Maybe<Scalars['String']>;
  labelEn?: Maybe<Scalars['String']>;
  type?: Maybe<PreStayGroupQuestionTypeEnum | `${PreStayGroupQuestionTypeEnum}`>;
  questionListOnTrue?: Maybe<Array<PreStayGroupQuestion>>;
  questionListOnFalse?: Maybe<Array<PreStayGroupQuestion>>;
};

export enum PreStayGroupQuestionTypeEnum {
  TYPE_PHONE_NUMBER = 'TYPE_PHONE_NUMBER',
  TYPE_TEXT = 'TYPE_TEXT',
  TYPE_DATE = 'TYPE_DATE',
  TYPE_DATETIME = 'TYPE_DATETIME',
  TYPE_BOOLEAN = 'TYPE_BOOLEAN',
  TYPE_LONGTEXT = 'TYPE_LONGTEXT'
}

export type Service = {
  __typename?: 'Service';
  id: Scalars['ID'];
  hotelId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  labelFr?: Maybe<Scalars['String']>;
  descFr?: Maybe<Scalars['String']>;
  labelEn?: Maybe<Scalars['String']>;
  descEn?: Maybe<Scalars['String']>;
  pictoUrl?: Maybe<Scalars['String']>;
  isRestaurant?: Maybe<Scalars['Boolean']>;
  autoAddBreakfast?: Maybe<Scalars['Boolean']>;
  isSpa?: Maybe<Scalars['Boolean']>;
  canBeSelectedInPreStay?: Maybe<Scalars['Boolean']>;
  canBeSelectedInConfirmation?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ServiceTypeEnum | `${ServiceTypeEnum}`>;
  providerType?: Maybe<ServiceProviderTypeEnum | `${ServiceProviderTypeEnum}`>;
  managerUserId?: Maybe<Scalars['String']>;
  planningWeekly?: Maybe<PlanningWeekly>;
  Event?: Maybe<Array<Event>>;
};

export enum ServiceTypeEnum {
  TYPE_RESTAURANT = 'TYPE_RESTAURANT',
  TYPE_SPA = 'TYPE_SPA',
  TYPE_EVENT = 'TYPE_EVENT',
  TYPE_PARKING = 'TYPE_PARKING'
}

export enum ServiceProviderTypeEnum {
  ALWAYS_AVAILABLE = 'ALWAYS_AVAILABLE',
  ASK_MANAGER = 'ASK_MANAGER'
}

export type PlanningWeekly = {
  __typename?: 'PlanningWeekly';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  status?: Maybe<EventStatusEnum | `${EventStatusEnum}`>;
  hotelId?: Maybe<Scalars['String']>;
  utcDate?: Maybe<Scalars['DateTime']>;
  sourceType?: Maybe<EventSourceTypeEnum | `${EventSourceTypeEnum}`>;
  serviceId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  source: Scalars['JSONObject'];
  Service?: Maybe<Service>;
  Hotel?: Maybe<Hotel>;
};

export enum EventStatusEnum {
  EVENT_STATUS_PENDING = 'EVENT_STATUS_PENDING',
  EVENT_STATUS_CONFIRMED = 'EVENT_STATUS_CONFIRMED',
  EVENT_STATUS_CANCELLED = 'EVENT_STATUS_CANCELLED',
  EVENT_STATUS_DELETED = 'EVENT_STATUS_DELETED'
}

export enum EventSourceTypeEnum {
  PLANNING_EVENT_SOURCE_PRESTAY = 'PLANNING_EVENT_SOURCE_PRESTAY',
  PLANNING_EVENT_SOURCE_RESERVATION = 'PLANNING_EVENT_SOURCE_RESERVATION',
  PLANNING_EVENT_SOURCE_RESERVATION_GROUP = 'PLANNING_EVENT_SOURCE_RESERVATION_GROUP',
  PLANNING_EVENT_SOURCE_CONFIRMATION = 'PLANNING_EVENT_SOURCE_CONFIRMATION'
}

export type Seasonality = {
  __typename?: 'Seasonality';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDay?: Maybe<Scalars['Float']>;
  startMonth?: Maybe<Scalars['Float']>;
  endDay?: Maybe<Scalars['Float']>;
  endMonth?: Maybe<Scalars['Float']>;
  startDayOfYear?: Maybe<Scalars['Float']>;
  endDayOfYear?: Maybe<Scalars['Float']>;
};

export type PhoneConfig = {
  __typename?: 'PhoneConfig';
  id?: Maybe<Scalars['String']>;
  hotelId?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  clic2CallSequenceId?: Maybe<Scalars['String']>;
};

export type NetworkConfig = {
  __typename?: 'NetworkConfig';
  id?: Maybe<Scalars['String']>;
  hotelId?: Maybe<Scalars['String']>;
  hostingConfigId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type PmsConfig = {
  __typename?: 'PmsConfig';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  type?: Maybe<PmsConfigTypeEnum | `${PmsConfigTypeEnum}`>;
  hotsoftConfig?: Maybe<PmsConfigHotsoft>;
};

export enum PmsConfigTypeEnum {
  HOTSOFT = 'HOTSOFT',
  DEMO = 'DEMO'
}

export type PmsConfigHotsoft = {
  __typename?: 'PmsConfigHotsoft';
  providerKey?: Maybe<Scalars['String']>;
  hotelCode?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  productCodeForReservationDiscount?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  project?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type GoogleConfig = {
  __typename?: 'GoogleConfig';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  placeId?: Maybe<Scalars['String']>;
};

export type TripAdvisorConfig = {
  __typename?: 'TripAdvisorConfig';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  reviewUrl?: Maybe<Scalars['String']>;
};

export type ConfirmationConfig = {
  __typename?: 'ConfirmationConfig';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  dest?: Maybe<Array<Scalars['String']>>;
  channel?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ConfirmationConfigTypeEnum | `${ConfirmationConfigTypeEnum}`>;
  dataTypeActive?: Maybe<Array<ConfirmationConfigDataTypeEnum | `${ConfirmationConfigDataTypeEnum}`>>;
  demoMode?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
};

export enum ConfirmationConfigTypeEnum {
  CONFIRMATION_TYPE_AUTO = 'CONFIRMATION_TYPE_AUTO',
  CONFIRMATION_TYPE_QUESTION = 'CONFIRMATION_TYPE_QUESTION',
  CONFIRMATION_TYPE_MANUAL = 'CONFIRMATION_TYPE_MANUAL',
  CONFIRMATION_TYPE_DATA = 'CONFIRMATION_TYPE_DATA'
}

export enum ConfirmationConfigDataTypeEnum {
  TYPE_PARKING = 'TYPE_PARKING',
  TYPE_COMMENT = 'TYPE_COMMENT',
  TYPE_ARRHES = 'TYPE_ARRHES',
  TYPE_SERVICES = 'TYPE_SERVICES'
}

export type PropositionConfig = {
  __typename?: 'PropositionConfig';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  demoMode?: Maybe<Scalars['Boolean']>;
  dest?: Maybe<Array<Scalars['String']>>;
};

export type HotelToken = {
  __typename?: 'HotelToken';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  status?: Maybe<HotelTokenStatusEnum | `${HotelTokenStatusEnum}`>;
  label?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  expiration?: Maybe<Scalars['DateTime']>;
};

export enum HotelTokenStatusEnum {
  STATUS_ACTIVE = 'STATUS_ACTIVE',
  STATUS_INACTIVE = 'STATUS_INACTIVE',
  STATUS_BURNED = 'STATUS_BURNED'
}

export type VideoConfig = {
  __typename?: 'VideoConfig';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  storageDir?: Maybe<Scalars['String']>;
  CameraList?: Maybe<Array<Camera>>;
  Hotel?: Maybe<Hotel>;
};

export type Camera = {
  __typename?: 'Camera';
  id: Scalars['ID'];
  videoConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Float']>;
  protocol?: Maybe<CameraProtocolEnum | `${CameraProtocolEnum}`>;
  retentionDays?: Maybe<Scalars['Float']>;
  codec?: Maybe<CameraCodecEnum | `${CameraCodecEnum}`>;
  streamingType?: Maybe<CameraStreamingTypeEnum | `${CameraStreamingTypeEnum}`>;
  VideoConfig: VideoConfig;
  Runner: Runner;
};

export enum CameraProtocolEnum {
  PROTOCOL_RTP = 'PROTOCOL_RTP',
  PROTOCOL_RTSP = 'PROTOCOL_RTSP'
}

export enum CameraCodecEnum {
  CODEC_HEVC = 'CODEC_HEVC',
  CODEC_H264 = 'CODEC_H264'
}

export enum CameraStreamingTypeEnum {
  HLS = 'HLS',
  DASH = 'DASH'
}

export type Runner = {
  __typename?: 'Runner';
  id: Scalars['ID'];
  cameraId?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['Float']>;
  startAttempt?: Maybe<Scalars['Float']>;
  status?: Maybe<RunnerStatusEnum | `${RunnerStatusEnum}`>;
  statusExpected?: Maybe<RunnerStatusEnum | `${RunnerStatusEnum}`>;
};

export enum RunnerStatusEnum {
  STATUS_RUNNING = 'STATUS_RUNNING',
  STATUS_STOPPED = 'STATUS_STOPPED',
  STATUS_ERROR = 'STATUS_ERROR'
}

export type PhoneAgentConfig = {
  __typename?: 'PhoneAgentConfig';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  callChannel?: Maybe<Scalars['String']>;
};

export type PhoneSipConfig = {
  __typename?: 'PhoneSipConfig';
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  server?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type Corp = {
  __typename?: 'Corp';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  Hotel?: Maybe<Array<Hotel>>;
};

export type FileEncrypted = {
  __typename?: 'FileEncrypted';
  id: Scalars['ID'];
  version?: Maybe<FileEncryptedVersionEnum | `${FileEncryptedVersionEnum}`>;
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum FileEncryptedVersionEnum {
  VERSION_1 = 'VERSION_1'
}

export type MailMessage = {
  __typename?: 'MailMessage';
  id: Scalars['ID'];
  providerId?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Scalars['String']>>;
  bcc?: Maybe<Array<Scalars['String']>>;
  replyTo?: Maybe<MailMessageAddress>;
  sender?: Maybe<MailMessageAddress>;
  subject?: Maybe<Scalars['String']>;
  status?: Maybe<MailMessageStatusEnum | `${MailMessageStatusEnum}`>;
  contentHTML?: Maybe<Scalars['String']>;
  History?: Maybe<Array<MailMessageHistory>>;
};

export enum MailMessageStatusEnum {
  STATUS_CREATED = 'STATUS_CREATED',
  STATUS_SENT = 'STATUS_SENT',
  STATUS_ERROR = 'STATUS_ERROR',
  STATUS_DELIVERED = 'STATUS_DELIVERED',
  STATUS_READ = 'STATUS_READ'
}

export type MailMessageHistory = {
  __typename?: 'MailMessageHistory';
  id: Scalars['ID'];
  mailMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<MailMessageStatusEnum | `${MailMessageStatusEnum}`>;
  date?: Maybe<Scalars['DateTime']>;
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatusEnum | `${NotificationStatusEnum}`>;
  type?: Maybe<NotificationTypeEnum | `${NotificationTypeEnum}`>;
  sourceId?: Maybe<Scalars['String']>;
  mailMessageId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['JSONObject']>;
};

export enum NotificationStatusEnum {
  STATUS_READ = 'STATUS_READ',
  STATUS_UNREAD = 'STATUS_UNREAD'
}

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<TemplateTypeEnum | `${TemplateTypeEnum}`>;
  contentFrHTML?: Maybe<Scalars['String']>;
  contentFrJSON?: Maybe<Scalars['JSONObject']>;
  contentEnHTML?: Maybe<Scalars['String']>;
  contentEnJSON?: Maybe<Scalars['JSONObject']>;
};

export enum TemplateTypeEnum {
  TYPE_HTML = 'TYPE_HTML',
  TYPE_UNLAYER = 'TYPE_UNLAYER'
}

export type NewsListResult = {
  __typename?: 'NewsListResult';
  nodes?: Maybe<Array<News>>;
  cursor?: Maybe<Cursor>;
};

export type News = {
  __typename?: 'News';
  id: Scalars['ID'];
  level?: Maybe<NewsLevelEnum | `${NewsLevelEnum}`>;
  date?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  contentHTML?: Maybe<Scalars['String']>;
  contentJSON?: Maybe<Scalars['JSONObject']>;
};

export enum NewsLevelEnum {
  INFO = 'INFO',
  WARNING = 'WARNING',
  DANGER = 'DANGER'
}

export type Cursor = {
  __typename?: 'Cursor';
  limit: Scalars['Float'];
  page: Scalars['Float'];
  totalPages?: Maybe<Scalars['Float']>;
  hasMore: Scalars['Boolean'];
};

export type CursorInput = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  origin: CustomerOriginEnum | `${CustomerOriginEnum}`;
  type?: Maybe<CustomerTypeEnum | `${CustomerTypeEnum}`>;
  gender?: Maybe<CustomerGenderEnum | `${CustomerGenderEnum}`>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['DateTime']>;
  sourceId?: Maybe<Scalars['String']>;
  mainEmail?: Maybe<Scalars['String']>;
  mainEmailStatus?: Maybe<EmailStatusEnum | `${EmailStatusEnum}`>;
  History: Array<CustomerHistory>;
  Contact: Array<Contact>;
  SegmentationMember: Array<SegmentationMember>;
  Reservation?: Maybe<Array<Reservation>>;
  Proposition?: Maybe<Array<Proposition>>;
  CustomerMetric?: Maybe<CustomerMetric>;
  CustomerEmail?: Maybe<Array<CustomerEmail>>;
  CustomerAddress?: Maybe<Array<CustomerAddress>>;
  CustomerCard?: Maybe<Array<CustomerCard>>;
  CustomerPhone?: Maybe<Array<CustomerPhone>>;
  CustomerNewsletterRegistration?: Maybe<Array<CustomerNewsletterRegistration>>;
};

export enum CustomerOriginEnum {
  ORIGIN_SHOP = 'ORIGIN_SHOP',
  ORIGIN_PMS_CONTACT = 'ORIGIN_PMS_CONTACT',
  ORIGIN_IMPORT = 'ORIGIN_IMPORT',
  ORIGIN_NEWSLETTER_REGISTRATION = 'ORIGIN_NEWSLETTER_REGISTRATION',
  ORIGIN_OTHER = 'ORIGIN_OTHER'
}

export enum CustomerTypeEnum {
  AGENT = 'AGENT',
  IND = 'IND',
  CORP = 'CORP'
}

export enum CustomerGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export enum EmailStatusEnum {
  BLACKLISTED = 'BLACKLISTED',
  UNKNOWN = 'UNKNOWN',
  VALID = 'VALID',
  INVALID = 'INVALID',
  OPT_OUT = 'OPT_OUT',
  BOUNCE_SOFT = 'BOUNCE_SOFT',
  BOUNCE_HARD = 'BOUNCE_HARD',
  ERROR = 'ERROR'
}

export type CustomerHistory = {
  __typename?: 'CustomerHistory';
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  type: CustomerHistoryTypeEnum | `${CustomerHistoryTypeEnum}`;
  customerId: Scalars['String'];
};

export enum CustomerHistoryTypeEnum {
  TYPE_CREATION = 'TYPE_CREATION',
  TYPE_PURCHASE = 'TYPE_PURCHASE',
  TYPE_RESERVATION_CREATE = 'TYPE_RESERVATION_CREATE',
  TYPE_MARKETING_OPTOUT = 'TYPE_MARKETING_OPTOUT'
}

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  pmsId: Scalars['String'];
  hotelId?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  levenScoreFirstName?: Maybe<Scalars['Float']>;
  levenScoreLastName?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  type?: Maybe<ContactTypeEnum | `${ContactTypeEnum}`>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Scalars['String']>;
  comment1?: Maybe<Scalars['String']>;
  comment2?: Maybe<Scalars['String']>;
  comment3?: Maybe<Scalars['String']>;
  comment4?: Maybe<Scalars['String']>;
  comment5?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  phoneNumberValid?: Maybe<Array<Scalars['String']>>;
  isEmailValid?: Maybe<Scalars['Boolean']>;
  forceIsFrench?: Maybe<Scalars['Boolean']>;
  phone1?: Maybe<Scalars['String']>;
  isPhone1Valid?: Maybe<Scalars['Boolean']>;
  phone2?: Maybe<Scalars['String']>;
  isPhone2Valid?: Maybe<Scalars['Boolean']>;
  Hotel: Hotel;
  isFrench: Scalars['Boolean'];
  phoneNumberFormatted?: Maybe<Scalars['String']>;
  greetingsFormatted?: Maybe<Scalars['String']>;
  Customer?: Maybe<Customer>;
  Token?: Maybe<Array<Token>>;
  Payment?: Maybe<Array<Payment>>;
};

export enum ContactTypeEnum {
  GUEST = 'GUEST',
  AGENT = 'AGENT',
  CORPORATE = 'CORPORATE',
  GROUP = 'GROUP',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER'
}

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  contactId?: Maybe<Scalars['String']>;
  hotelId?: Maybe<Scalars['String']>;
  terminalId?: Maybe<Scalars['String']>;
  mailMessageId?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  captureDate?: Maybe<Scalars['DateTime']>;
  status: TokenStatusEnum | `${TokenStatusEnum}`;
  type?: Maybe<TokenTypeEnum | `${TokenTypeEnum}`>;
  sendStatus?: Maybe<TokenSendStatusEnum | `${TokenSendStatusEnum}`>;
  publicId?: Maybe<Scalars['String']>;
  publicSalt?: Maybe<Scalars['String']>;
  isError?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  provider?: Maybe<BillingConfigTypeEnum | `${BillingConfigTypeEnum}`>;
  publicUrl?: Maybe<Scalars['String']>;
  lastError?: Maybe<Scalars['String']>;
  receiptInfo?: Maybe<ReceiptInfo>;
  Hotel: Hotel;
  publicPath: Scalars['String'];
  Options?: Maybe<Scalars['JSONObject']>;
  MailMessage?: Maybe<MailMessage>;
  Contact?: Maybe<Contact>;
  Charge?: Maybe<Array<Charge>>;
  Terminal?: Maybe<Terminal>;
};

export enum TokenStatusEnum {
  STATUS_INITIAL = 'STATUS_INITIAL',
  STATUS_PENDING_CUSTOMER = 'STATUS_PENDING_CUSTOMER',
  STATUS_DONE = 'STATUS_DONE',
  STATUS_DELETED = 'STATUS_DELETED'
}

export enum TokenTypeEnum {
  TYPE_LINK = 'TYPE_LINK',
  TYPE_TERMINAL = 'TYPE_TERMINAL'
}

export enum TokenSendStatusEnum {
  SEND_STATUS_SENT = 'SEND_STATUS_SENT',
  SEND_STATUS_IDLE = 'SEND_STATUS_IDLE',
  SEND_STATUS_ERROR = 'SEND_STATUS_ERROR',
  SEND_STATUS_DELIVERED = 'SEND_STATUS_DELIVERED',
  SEND_STATUS_READ = 'SEND_STATUS_READ'
}

export type ReceiptInfo = {
  __typename?: 'ReceiptInfo';
  last4?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['String']>;
  expYear?: Maybe<Scalars['String']>;
};

export type Charge = {
  __typename?: 'Charge';
  id: Scalars['ID'];
  paymentId?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  amount?: Maybe<Scalars['Float']>;
  refundAmount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  status?: Maybe<ChargeStatusEnum | `${ChargeStatusEnum}`>;
  type?: Maybe<ChargeTypeEnum | `${ChargeTypeEnum}`>;
  source?: Maybe<ChargeSourceEnum | `${ChargeSourceEnum}`>;
  lastError?: Maybe<Scalars['String']>;
};

export enum ChargeStatusEnum {
  CHARGE_STATUS_CREATED = 'CHARGE_STATUS_CREATED',
  CHARGE_STATUS_REFUND = 'CHARGE_STATUS_REFUND',
  CHARGE_STATUS_COMPLETE = 'CHARGE_STATUS_COMPLETE',
  CHARGE_STATUS_ERROR = 'CHARGE_STATUS_ERROR'
}

export enum ChargeTypeEnum {
  CHARGE_STATUS_REFUND = 'CHARGE_STATUS_REFUND',
  CHARGE_STATUS_CHARGING = 'CHARGE_STATUS_CHARGING'
}

export enum ChargeSourceEnum {
  CHARGE_SOURCE_TOKEN = 'CHARGE_SOURCE_TOKEN',
  CHARGE_SOURCE_PAYMENT = 'CHARGE_SOURCE_PAYMENT'
}

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID'];
  contactId?: Maybe<Scalars['String']>;
  hotelId?: Maybe<Scalars['String']>;
  mailMessageId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  captureDate?: Maybe<Scalars['DateTime']>;
  status: PaymentStatusEnum | `${PaymentStatusEnum}`;
  sendStatus?: Maybe<PaymentSendStatusEnum | `${PaymentSendStatusEnum}`>;
  type?: Maybe<PaymentTypeEnum | `${PaymentTypeEnum}`>;
  captureType?: Maybe<PaymentCaptureTypeEnum | `${PaymentCaptureTypeEnum}`>;
  captureStatus?: Maybe<PaymentCaptureStatusEnum | `${PaymentCaptureStatusEnum}`>;
  captureValue?: Maybe<Scalars['Float']>;
  refundValue?: Maybe<Scalars['Float']>;
  publicId?: Maybe<Scalars['String']>;
  publicSalt?: Maybe<Scalars['String']>;
  isError?: Maybe<Scalars['Boolean']>;
  mobilePhoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  provider?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  lastError?: Maybe<Scalars['String']>;
  receiptInfo?: Maybe<ReceiptInfo>;
  Hotel: Hotel;
  publicPath: Scalars['String'];
  PaymentOptions?: Maybe<PaymentOptions>;
  hasValidEmail?: Maybe<Scalars['Boolean']>;
  MailMessage?: Maybe<MailMessage>;
  Contact?: Maybe<Contact>;
};

export enum PaymentStatusEnum {
  STATUS_INITIAL = 'STATUS_INITIAL',
  STATUS_PENDING_CUSTOMER = 'STATUS_PENDING_CUSTOMER',
  STATUS_DONE = 'STATUS_DONE',
  STATUS_REFUND = 'STATUS_REFUND',
  STATUS_DELETED = 'STATUS_DELETED'
}

export enum PaymentSendStatusEnum {
  SEND_STATUS_SENT = 'SEND_STATUS_SENT',
  SEND_STATUS_IDLE = 'SEND_STATUS_IDLE',
  SEND_STATUS_ERROR = 'SEND_STATUS_ERROR',
  SEND_STATUS_DELIVERED = 'SEND_STATUS_DELIVERED',
  SEND_STATUS_READ = 'SEND_STATUS_READ'
}

export enum PaymentTypeEnum {
  TYPE_MANUAL = 'TYPE_MANUAL',
  TYPE_PROPOSITION = 'TYPE_PROPOSITION'
}

export enum PaymentCaptureTypeEnum {
  CAPTURE_TYPE_PRE_AUTH = 'CAPTURE_TYPE_PRE_AUTH',
  CAPTURE_TYPE_FULL = 'CAPTURE_TYPE_FULL'
}

export enum PaymentCaptureStatusEnum {
  CAPTURE_STATUS_CAPTURED = 'CAPTURE_STATUS_CAPTURED',
  CAPTURE_STATUS_NOT_CAPTURED = 'CAPTURE_STATUS_NOT_CAPTURED'
}

export type PaymentOptions = PaymentPaytweakOptions | PaymentStripeOptions;

export type PaymentPaytweakOptions = {
  __typename?: 'PaymentPaytweakOptions';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  linkId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PaymentStripeOptions = {
  __typename?: 'PaymentStripeOptions';
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentMethodId?: Maybe<Scalars['String']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
};

export type SegmentationMember = {
  __typename?: 'SegmentationMember';
  id: Scalars['ID'];
  segmentationId: Scalars['String'];
  customerId: Scalars['String'];
  Segmentation?: Maybe<Segmentation>;
  Customer?: Maybe<Customer>;
};

export type Segmentation = {
  __typename?: 'Segmentation';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  status: SegmentationSyncStatus | `${SegmentationSyncStatus}`;
  lastSyncDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  displayCustomerDashboard?: Maybe<Scalars['Boolean']>;
  picto?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  options?: Maybe<SegmentationOptions>;
  lastError?: Maybe<Scalars['String']>;
};

export enum SegmentationSyncStatus {
  SYNCED = 'SYNCED',
  SYNCING = 'SYNCING',
  STOPPED = 'STOPPED',
  ERROR = 'ERROR',
  TODO = 'TODO',
  CREATED = 'CREATED'
}

export type SegmentationOptions = {
  __typename?: 'SegmentationOptions';
  stay?: Maybe<SegmentationOptionsStay>;
  origin?: Maybe<SegmentationOptionsOrigin>;
  customer?: Maybe<SegmentationOptionsCustomer>;
  contact?: Maybe<SegmentationOptionsContact>;
};

export type SegmentationOptionsStay = {
  __typename?: 'SegmentationOptionsStay';
  type?: Maybe<SegmentationHasStayTypeEnum | `${SegmentationHasStayTypeEnum}`>;
  statusList?: Maybe<Array<ReservationStatusEnum | `${ReservationStatusEnum}`>>;
  when?: Maybe<SegmentationStayWhenEnum | `${SegmentationStayWhenEnum}`>;
  where?: Maybe<SegmentationStayWhereEnum | `${SegmentationStayWhereEnum}`>;
  dateStart?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  nbNight?: Maybe<SegmentationNbNightCriteria>;
  nbReservations?: Maybe<SegmentationNbReservationCriteria>;
  hotelList?: Maybe<Array<Scalars['String']>>;
  nbAdults?: Maybe<SegmentationNbAdultsStayEnum | `${SegmentationNbAdultsStayEnum}`>;
  nbChildren?: Maybe<SegmentationNbChildrenStayEnum | `${SegmentationNbChildrenStayEnum}`>;
  hasSpa?: Maybe<Scalars['Boolean']>;
};

export enum SegmentationHasStayTypeEnum {
  YES = 'YES',
  NO = 'NO'
}

export enum ReservationStatusEnum {
  CONFIRMED = 'CONFIRMED',
  DEPARTED = 'DEPARTED',
  BLOCKED = 'BLOCKED',
  CANCELLED = 'CANCELLED',
  STAYING = 'STAYING',
  WAITLIST = 'WAITLIST',
  REINSTATE = 'REINSTATE',
  PROVISIONAL = 'PROVISIONAL',
  OTHER = 'OTHER'
}

export enum SegmentationStayWhenEnum {
  BETWEEN = 'BETWEEN',
  BESIDE = 'BESIDE'
}

export enum SegmentationStayWhereEnum {
  IN_UNION = 'IN_UNION',
  BESIDE = 'BESIDE',
  IN_INTERSECTION = 'IN_INTERSECTION'
}

export type SegmentationNbNightCriteria = {
  __typename?: 'SegmentationNbNightCriteria';
  type?: Maybe<SegmentationComparisonEnum | `${SegmentationComparisonEnum}`>;
  value?: Maybe<Scalars['Float']>;
};

export enum SegmentationComparisonEnum {
  TYPE_EQUAL = 'TYPE_EQUAL',
  TYPE_MORE_THAN = 'TYPE_MORE_THAN',
  TYPE_LESS_THAN = 'TYPE_LESS_THAN'
}

export type SegmentationNbReservationCriteria = {
  __typename?: 'SegmentationNbReservationCriteria';
  type?: Maybe<SegmentationComparisonEnum | `${SegmentationComparisonEnum}`>;
  value?: Maybe<Scalars['Float']>;
};

export enum SegmentationNbAdultsStayEnum {
  ONE = 'ONE',
  TWO = 'TWO',
  MORE = 'MORE'
}

export enum SegmentationNbChildrenStayEnum {
  ZERO = 'ZERO',
  ANY = 'ANY'
}

export type SegmentationOptionsOrigin = {
  __typename?: 'SegmentationOptionsOrigin';
  type?: Maybe<SegmentationOptionsOriginTypeEnum | `${SegmentationOptionsOriginTypeEnum}`>;
  hotelList?: Maybe<Array<Scalars['String']>>;
};

export enum SegmentationOptionsOriginTypeEnum {
  ORIGIN_PMS = 'ORIGIN_PMS',
  ORIGIN_WIDGET = 'ORIGIN_WIDGET',
  ORIGIN_ECOMMERCE = 'ORIGIN_ECOMMERCE'
}

export type SegmentationOptionsCustomer = {
  __typename?: 'SegmentationOptionsCustomer';
  type?: Maybe<Scalars['String']>;
  emailDomain?: Maybe<SegmentationOptionsCustomerEmailDomain>;
};

export type SegmentationOptionsCustomerEmailDomain = {
  __typename?: 'SegmentationOptionsCustomerEmailDomain';
  type?: Maybe<SegmentationOptionsCustomerEmailDomainTypeEnum | `${SegmentationOptionsCustomerEmailDomainTypeEnum}`>;
  domainList?: Maybe<Array<Scalars['String']>>;
};

export enum SegmentationOptionsCustomerEmailDomainTypeEnum {
  TYPE_INCLUDE = 'TYPE_INCLUDE',
  TYPE_EXCLUDE = 'TYPE_EXCLUDE'
}

export type SegmentationOptionsContact = {
  __typename?: 'SegmentationOptionsContact';
  gender?: Maybe<ContactGenderEnum | `${ContactGenderEnum}`>;
  country?: Maybe<Array<Scalars['String']>>;
};

export enum ContactGenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['ID'];
  pmsId: Scalars['String'];
  corpId?: Maybe<Scalars['String']>;
  hotelId: Scalars['String'];
  status: ReservationStatusEnum | `${ReservationStatusEnum}`;
  type: ReservationTypeEnum | `${ReservationTypeEnum}`;
  arrivalDate: Scalars['DateTime'];
  creationDate: Scalars['DateTime'];
  departureDate: Scalars['DateTime'];
  updateDate: Scalars['DateTime'];
  nbNight: Scalars['Float'];
  daysBetweenCreatedAndArrival: Scalars['Float'];
  nbAdults?: Maybe<Scalars['Float']>;
  nbChildren?: Maybe<Scalars['Float']>;
  nbSpecial?: Maybe<Scalars['Float']>;
  confirmationSent?: Maybe<Scalars['Boolean']>;
  channel?: Maybe<Scalars['String']>;
  ReservationPricePackage?: Maybe<Array<ReservationPricePackage>>;
  ReservationRate: Array<ReservationRate>;
  DefaultRate?: Maybe<ReservationRate>;
  CancelConditionValue?: Maybe<PriceRateCancelConditionItemValueEnum | `${PriceRateCancelConditionItemValueEnum}`>;
  ReservationGroup: Array<ReservationGroup>;
  Contact?: Maybe<Contact>;
  Hotel: Hotel;
  EventList?: Maybe<Array<Event>>;
  ReservationRoom?: Maybe<ReservationRoom>;
  ReservationGuest?: Maybe<Array<ReservationGuest>>;
  totalIncTax: Scalars['Float'];
  npPeople: Scalars['Float'];
  Checkin?: Maybe<Checkin>;
  Seasonality?: Maybe<Seasonality>;
};

export enum ReservationTypeEnum {
  DAY_GUEST = 'DAY_GUEST',
  NIGHT_GUEST = 'NIGHT_GUEST'
}

export type ReservationPricePackage = {
  __typename?: 'ReservationPricePackage';
  id: Scalars['ID'];
  reservationId: Scalars['String'];
  optionId?: Maybe<Scalars['String']>;
  includedInTotal?: Maybe<Scalars['Boolean']>;
  ammountIncVat?: Maybe<Scalars['String']>;
  ammountExcVat?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  startDate: Scalars['DateTime'];
  Option?: Maybe<Option>;
};

export type ReservationRate = {
  __typename?: 'ReservationRate';
  id: Scalars['ID'];
  reservationId: Scalars['String'];
  priceRateTypeId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  amountIncVat?: Maybe<Scalars['Float']>;
  amountExcVat?: Maybe<Scalars['Float']>;
  currencyCode?: Maybe<Scalars['String']>;
  PriceRateType?: Maybe<PriceRateType>;
};

export type ReservationGroup = {
  __typename?: 'ReservationGroup';
  id: Scalars['ID'];
  contactId: Scalars['String'];
  hotelId?: Maybe<Scalars['String']>;
  arrivalDate: Scalars['DateTime'];
  departureDate: Scalars['DateTime'];
  channel?: Maybe<Scalars['String']>;
  ReservationGroupLink?: Maybe<Array<ReservationGroupLink>>;
  ReservationGroupLinkConfirmed?: Maybe<Array<ReservationGroupLink>>;
  Hotel?: Maybe<Hotel>;
  Contact?: Maybe<Contact>;
  totalIncTax?: Maybe<Scalars['Float']>;
  totalPeople?: Maybe<Scalars['Float']>;
  DefaultRate?: Maybe<ReservationRate>;
  wholeDates?: Maybe<Scalars['JSONObject']>;
};

export type ReservationGroupLink = {
  __typename?: 'ReservationGroupLink';
  id: Scalars['ID'];
  groupId: Scalars['String'];
  reservationId?: Maybe<Scalars['String']>;
  forceAdd?: Maybe<Scalars['Boolean']>;
  forceRemove?: Maybe<Scalars['Boolean']>;
  Reservation?: Maybe<Reservation>;
  Group?: Maybe<ReservationGroup>;
};

export type ReservationRoom = {
  __typename?: 'ReservationRoom';
  id: Scalars['ID'];
  reservationId: Scalars['String'];
  roomId?: Maybe<Scalars['String']>;
  Room?: Maybe<Room>;
};

export type ReservationGuest = {
  __typename?: 'ReservationGuest';
  id: Scalars['ID'];
  reservationId: Scalars['String'];
  contactId: Scalars['String'];
  Contact?: Maybe<Contact>;
};

export type Checkin = {
  __typename?: 'Checkin';
  id: Scalars['ID'];
  reservationId?: Maybe<Scalars['String']>;
  status?: Maybe<CheckinStatusEnum | `${CheckinStatusEnum}`>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  tokenId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mail?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  Reservation?: Maybe<Reservation>;
  CheckinIdentityPicture?: Maybe<Array<CheckinIdentityPicture>>;
};

export enum CheckinStatusEnum {
  STATUS_TODO = 'STATUS_TODO',
  STATUS_PRE_CHECK_IN = 'STATUS_PRE_CHECK_IN',
  STATUS_EXPIRED = 'STATUS_EXPIRED',
  STATUS_DONE = 'STATUS_DONE'
}

export type CheckinIdentityPicture = {
  __typename?: 'CheckinIdentityPicture';
  id: Scalars['ID'];
  checkinId?: Maybe<Scalars['String']>;
  encryptedFileId?: Maybe<Scalars['String']>;
};

export type Proposition = {
  __typename?: 'Proposition';
  id: Scalars['ID'];
  publicId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['DateTime']>;
  hotelId: Scalars['String'];
  rowSelectedId?: Maybe<Scalars['String']>;
  status: PropositionStatusEnum | `${PropositionStatusEnum}`;
  sentStatus?: Maybe<PropositionSentStatusEnum | `${PropositionSentStatusEnum}`>;
  pushStatus?: Maybe<PropositionPushStatusEnum | `${PropositionPushStatusEnum}`>;
  pushPms?: Maybe<PropositionPushPmsEnum | `${PropositionPushPmsEnum}`>;
  creationDate?: Maybe<Scalars['DateTime']>;
  nbRooms?: Maybe<Scalars['Float']>;
  nbAdults?: Maybe<Scalars['Float']>;
  nbChildren?: Maybe<Scalars['Float']>;
  nbSpecial?: Maybe<Scalars['Float']>;
  propositionRow?: Maybe<Array<PropositionRow>>;
  mailMessageId?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  Hotel: Hotel;
  Contact?: Maybe<Contact>;
  isExpired: Scalars['Boolean'];
  rowsHaveSameDates: Scalars['Boolean'];
  Payment?: Maybe<Payment>;
};

export enum PropositionStatusEnum {
  STATUS_DRAFT = 'STATUS_DRAFT',
  STATUS_VALID = 'STATUS_VALID',
  STATUS_SENT = 'STATUS_SENT',
  STATUS_SELECTED = 'STATUS_SELECTED',
  STATUS_WAITING_PAYMENT = 'STATUS_WAITING_PAYMENT',
  STATUS_CONFIRMED = 'STATUS_CONFIRMED',
  STATUS_DELETED = 'STATUS_DELETED',
  STATUS_DELIVERED = 'STATUS_DELIVERED',
  STATUS_READ = 'STATUS_READ',
  STATUS_ERROR = 'STATUS_ERROR'
}

export enum PropositionSentStatusEnum {
  STATUS_NOT_SENT = 'STATUS_NOT_SENT',
  STATUS_SENT = 'STATUS_SENT'
}

export enum PropositionPushStatusEnum {
  PUSH_ERROR = 'PUSH_ERROR',
  PUSH_NONE = 'PUSH_NONE',
  PUSH_DONE = 'PUSH_DONE',
  PUSH_RESERVATION_DONE = 'PUSH_RESERVATION_DONE',
  PUSH_DISCOUNT_DONE = 'PUSH_DISCOUNT_DONE',
  PUSH_OPTIONS_DONE = 'PUSH_OPTIONS_DONE',
  PUSH_CONFIRMED_DONE = 'PUSH_CONFIRMED_DONE'
}

export enum PropositionPushPmsEnum {
  PROPOSITION_PUSH_NONE = 'PROPOSITION_PUSH_NONE',
  PROPOSITION_PUSH_PRE_PROVISONAL = 'PROPOSITION_PUSH_PRE_PROVISONAL',
  PROPOSITION_PUSH_POST_WAITLIST = 'PROPOSITION_PUSH_POST_WAITLIST',
  PROPOSITION_PUSH_POST_CONFIRMED = 'PROPOSITION_PUSH_POST_CONFIRMED'
}

export type PropositionRow = {
  __typename?: 'PropositionRow';
  id?: Maybe<Scalars['ID']>;
  departureDate?: Maybe<Scalars['DateTime']>;
  arrivalDate?: Maybe<Scalars['DateTime']>;
  paymentCondition?: Maybe<PropositionRowPaymentConditionEnum | `${PropositionRowPaymentConditionEnum}`>;
  propositionId?: Maybe<Scalars['String']>;
  roomList: Array<PropositionRowRoom>;
  comment?: Maybe<Scalars['String']>;
  totalIncTax: Scalars['Float'];
  total?: Maybe<Total>;
  totalToValid?: Maybe<Scalars['Float']>;
  nbNights: Scalars['Float'];
  hasSameDates: Scalars['Float'];
};

export enum PropositionRowPaymentConditionEnum {
  CONDITION_PRE_AUTH = 'CONDITION_PRE_AUTH',
  CONDITION_0 = 'CONDITION_0',
  CONDITION_10 = 'CONDITION_10',
  CONDITION_15 = 'CONDITION_15',
  CONDITION_20 = 'CONDITION_20',
  CONDITION_30 = 'CONDITION_30',
  CONDITION_50 = 'CONDITION_50',
  CONDITION_100 = 'CONDITION_100'
}

export type PropositionRowRoom = {
  __typename?: 'PropositionRowRoom';
  sameConditions?: Maybe<Scalars['Boolean']>;
  pmsId?: Maybe<Scalars['String']>;
  discountPushed?: Maybe<Scalars['Boolean']>;
  confirmedPush?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  optionList?: Maybe<Array<PropositionRowRoomOption>>;
  roomIndex: Scalars['Float'];
  isUpgrade?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<Discount>;
  hasDelogement?: Maybe<Scalars['Boolean']>;
  itemList: Array<PropositionRowRoomNight>;
  Option?: Maybe<Array<PropositionRowRoomOption>>;
  DefaultRateType?: Maybe<PriceRateType>;
  StayDates?: Maybe<PropositionRowRoomStayDates>;
};

export type PropositionRowRoomOption = {
  __typename?: 'PropositionRowRoomOption';
  optionId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  amountIncVat?: Maybe<Scalars['Float']>;
  discount?: Maybe<Discount>;
  optionPushed?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  Option?: Maybe<Option>;
};

export type Discount = {
  __typename?: 'Discount';
  forced?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['String']>;
};

export type PropositionRowRoomNight = {
  __typename?: 'PropositionRowRoomNight';
  roomId?: Maybe<Scalars['String']>;
  dateStamp: Scalars['Float'];
  rateId?: Maybe<Scalars['String']>;
  rateCode?: Maybe<Scalars['String']>;
  rateTypeId?: Maybe<Scalars['String']>;
  rateIncVat?: Maybe<Scalars['Float']>;
  rateExcVat?: Maybe<Scalars['Float']>;
  priceRateList?: Maybe<Array<PriceRate>>;
  error?: Maybe<Scalars['String']>;
  discountPushed?: Maybe<Scalars['Boolean']>;
  pmsId?: Maybe<Scalars['String']>;
  confirmedPush?: Maybe<Scalars['Boolean']>;
  Room: Room;
  PriceRate?: Maybe<PriceRate>;
  date?: Maybe<Scalars['DateTime']>;
};

export type PriceRate = {
  __typename?: 'PriceRate';
  id?: Maybe<Scalars['String']>;
  hotelId: Scalars['String'];
  roomTypeId?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  typeId?: Maybe<Scalars['String']>;
  latest?: Maybe<Scalars['Boolean']>;
  nbAdults?: Maybe<Scalars['Float']>;
  nbChildren?: Maybe<Scalars['Float']>;
  nbSpecial?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  roomType: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  valueIncTax: Scalars['Float'];
  valueExcTax: Scalars['Float'];
  PriceRateType?: Maybe<PriceRateType>;
};

export type PropositionRowRoomStayDates = {
  __typename?: 'PropositionRowRoomStayDates';
  arrivalDate?: Maybe<Scalars['DateTime']>;
  departureDate?: Maybe<Scalars['DateTime']>;
};

export type Total = {
  __typename?: 'Total';
  hostingTotal: Scalars['Float'];
  extraTotal: Scalars['Float'];
  totalFull: Scalars['Float'];
  total: Scalars['Float'];
};

export type CustomerMetric = {
  __typename?: 'CustomerMetric';
  id: Scalars['ID'];
  customerId: Scalars['String'];
  caTotal?: Maybe<Scalars['Float']>;
  nbReservation?: Maybe<Scalars['Float']>;
  nbNight?: Maybe<Scalars['Float']>;
  nbProposition?: Maybe<Scalars['Float']>;
};

export type CustomerEmail = {
  __typename?: 'CustomerEmail';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  status?: Maybe<EmailStatusEnum | `${EmailStatusEnum}`>;
  Customer?: Maybe<Customer>;
  Contact?: Maybe<Contact>;
};

export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sourceType?: Maybe<CustomerOriginEnum | `${CustomerOriginEnum}`>;
  customerId?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  Contact?: Maybe<Contact>;
};

export type CustomerCard = {
  __typename?: 'CustomerCard';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  sourceType: CustomerOriginEnum | `${CustomerOriginEnum}`;
  sourceId?: Maybe<Scalars['String']>;
  type?: Maybe<CustomerTypeEnum | `${CustomerTypeEnum}`>;
  gender?: Maybe<CustomerGenderEnum | `${CustomerGenderEnum}`>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['DateTime']>;
};

export type CustomerPhone = {
  __typename?: 'CustomerPhone';
  id: Scalars['ID'];
  corpId?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  Contact: Contact;
};

export type CustomerNewsletterRegistration = {
  __typename?: 'CustomerNewsletterRegistration';
  id: Scalars['ID'];
  customerId?: Maybe<Scalars['String']>;
  newsletterRegistrationId?: Maybe<Scalars['String']>;
  Customer?: Maybe<Customer>;
  NewsletterRegistration?: Maybe<NewsletterRegistration>;
};

export type NewsletterRegistration = {
  __typename?: 'NewsletterRegistration';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  ip?: Maybe<Scalars['String']>;
  Hotel?: Maybe<Hotel>;
};

export type CustomerListResult = {
  __typename?: 'CustomerListResult';
  nodes: Array<Customer>;
  cursor: Cursor;
};

export type OrderInput = {
  field?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<orderDirectionEnum | `${orderDirectionEnum}`>;
};

export enum orderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type CustomerListInput = {
  origin?: InputMaybe<CustomerOriginEnum | `${CustomerOriginEnum}`>;
};

export type SearchResult = Customer | Contact | Reservation;

export type SegmentationCountInput = {
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<SegmentationOptionsInput>;
};

export type SegmentationOptionsInput = {
  stay?: InputMaybe<SegmentationOptionsStayInput>;
  origin?: InputMaybe<SegmentationOptionsOriginInput>;
  customer?: InputMaybe<SegmentationOptionsCustomerInput>;
  contact?: InputMaybe<SegmentationOptionsContactInput>;
};

export type SegmentationOptionsStayInput = {
  type?: InputMaybe<SegmentationHasStayTypeEnum | `${SegmentationHasStayTypeEnum}`>;
  statusList?: InputMaybe<Array<ReservationStatusEnum | `${ReservationStatusEnum}`>>;
  when?: InputMaybe<SegmentationStayWhenEnum | `${SegmentationStayWhenEnum}`>;
  where?: InputMaybe<SegmentationStayWhereEnum | `${SegmentationStayWhereEnum}`>;
  dateStart?: InputMaybe<Scalars['DateTime']>;
  dateEnd?: InputMaybe<Scalars['DateTime']>;
  nbNight?: InputMaybe<SegmentationNbNightCriteriaInput>;
  nbReservations?: InputMaybe<SegmentationNbReservationCriteriaInput>;
  hotelList?: InputMaybe<Array<Scalars['String']>>;
  nbAdults?: InputMaybe<SegmentationNbAdultsStayEnum | `${SegmentationNbAdultsStayEnum}`>;
  nbChildren?: InputMaybe<SegmentationNbChildrenStayEnum | `${SegmentationNbChildrenStayEnum}`>;
  hasSpa?: InputMaybe<Scalars['Boolean']>;
};

export type SegmentationNbNightCriteriaInput = {
  type?: InputMaybe<SegmentationComparisonEnum | `${SegmentationComparisonEnum}`>;
  value?: InputMaybe<Scalars['Float']>;
};

export type SegmentationNbReservationCriteriaInput = {
  type?: InputMaybe<SegmentationComparisonEnum | `${SegmentationComparisonEnum}`>;
  value?: InputMaybe<Scalars['Float']>;
};

export type SegmentationOptionsOriginInput = {
  type?: InputMaybe<SegmentationOptionsOriginTypeEnum | `${SegmentationOptionsOriginTypeEnum}`>;
  hotelList?: InputMaybe<Array<Scalars['String']>>;
};

export type SegmentationOptionsCustomerInput = {
  type?: InputMaybe<Scalars['String']>;
  emailDomain?: InputMaybe<SegmentationOptionsCustomerEmailDomainInput>;
};

export type SegmentationOptionsCustomerEmailDomainInput = {
  type?: InputMaybe<SegmentationOptionsCustomerEmailDomainTypeEnum | `${SegmentationOptionsCustomerEmailDomainTypeEnum}`>;
  domainList?: InputMaybe<Array<Scalars['String']>>;
};

export type SegmentationOptionsContactInput = {
  gender?: InputMaybe<ContactGenderEnum | `${ContactGenderEnum}`>;
  country?: InputMaybe<Array<Scalars['String']>>;
};

export type SegmentationEmailMemberCursorResult = {
  __typename?: 'SegmentationEmailMemberCursorResult';
  nodes: Array<SegmentationEmailMember>;
  cursor: Cursor;
};

export type SegmentationEmailMember = {
  __typename?: 'SegmentationEmailMember';
  id: Scalars['ID'];
  segmentationId: Scalars['String'];
  customerEmailId: Scalars['String'];
  CustomerEmail?: Maybe<CustomerEmail>;
  Segmentation?: Maybe<Segmentation>;
};

export type Target = {
  __typename?: 'Target';
  id: Scalars['ID'];
  clientId: Scalars['String'];
  status: TargetStatusEnum | `${TargetStatusEnum}`;
  hotelIds: Array<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  type: TargetTypeEnum | `${TargetTypeEnum}`;
  channelList?: Maybe<Array<Channel>>;
  lastActive?: Maybe<Scalars['DateTime']>;
};

export enum TargetStatusEnum {
  TARGET_AVAILABLE = 'TARGET_AVAILABLE',
  TARGET_UNAVAILABLE = 'TARGET_UNAVAILABLE'
}

export enum TargetTypeEnum {
  TARGET_CUSTOMER = 'TARGET_CUSTOMER',
  TARGET_AGENT = 'TARGET_AGENT'
}

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ID'];
  hotelId: Scalars['String'];
  type: ChannelTypeEnum | `${ChannelTypeEnum}`;
  status?: Maybe<ChannelStatusEnum | `${ChannelStatusEnum}`>;
  options?: Maybe<Scalars['JSONObject']>;
  messageList: Array<Message>;
  ChannelTarget: Array<ChannelTarget>;
};

export enum ChannelTypeEnum {
  TYPE_P2P = 'TYPE_P2P',
  TYPE_CUSTOMER = 'TYPE_CUSTOMER'
}

export enum ChannelStatusEnum {
  STATUS_OPEN = 'STATUS_OPEN',
  STATUS_CLOSE = 'STATUS_CLOSE',
  STATUS_ARCHIVE = 'STATUS_ARCHIVE'
}

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  channelId: Scalars['String'];
  content?: Maybe<Array<MessageContent>>;
  senderId: Scalars['String'];
};

export type MessageContent = {
  __typename?: 'MessageContent';
  type: MessageContentTypeEnum | `${MessageContentTypeEnum}`;
  value: Scalars['String'];
  options: Scalars['JSONObject'];
};

export enum MessageContentTypeEnum {
  TYPE_TEXT = 'TYPE_TEXT'
}

export type ChannelTarget = {
  __typename?: 'ChannelTarget';
  id: Scalars['ID'];
  targetId: Scalars['String'];
  channelId: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  isWriting?: Maybe<Scalars['Boolean']>;
};

export type ReservationListForCheckinInput = {
  hotelIds?: InputMaybe<Array<Scalars['String']>>;
  arrivalDate?: InputMaybe<Scalars['DateTime']>;
  orderField?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
};

export type ReservationListResult = {
  __typename?: 'ReservationListResult';
  nodes: Array<Reservation>;
  cursor: Cursor;
};

export type ReservationFilterInput = {
  hotelId?: InputMaybe<Scalars['String']>;
  pmsId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReservationStatusEnum | `${ReservationStatusEnum}`>;
  contactId?: InputMaybe<Scalars['String']>;
  arrivalFrom?: InputMaybe<Scalars['DateTime']>;
  arrivalTo?: InputMaybe<Scalars['DateTime']>;
  departureFrom?: InputMaybe<Scalars['DateTime']>;
  departureTo?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
};

export type ReservationGroupListResult = {
  __typename?: 'ReservationGroupListResult';
  cursor: Cursor;
  nodes: Array<ReservationGroup>;
  input: Scalars['JSONObject'];
};

export type ReservationGroupListInput = {
  hotelIds?: InputMaybe<Array<Scalars['String']>>;
  reservationPmsId?: InputMaybe<Scalars['String']>;
  orderField?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
};

export type ReservationPricePackageBlacklist = {
  __typename?: 'ReservationPricePackageBlacklist';
  id: Scalars['ID'];
  PLUCode: Scalars['String'];
  hotelId: Scalars['String'];
};

export type RoomAvailibility = {
  __typename?: 'RoomAvailibility';
  room: Room;
  availibilityList: Array<RoomAvailibilityItem>;
};

export type RoomAvailibilityItem = {
  __typename?: 'RoomAvailibilityItem';
  roomId: Scalars['String'];
  roomNumber: Scalars['String'];
  date: Scalars['DateTime'];
  isAvailable: Scalars['Boolean'];
};

export type PropositionAvailibilityInput = {
  hotelId?: InputMaybe<Scalars['String']>;
  arrivalDate?: InputMaybe<Scalars['DateTime']>;
  departureDate?: InputMaybe<Scalars['DateTime']>;
  nbAdults?: InputMaybe<Scalars['Float']>;
  nbChildren?: InputMaybe<Scalars['Float']>;
  nbSpecial?: InputMaybe<Scalars['Float']>;
};

export type PropositionUpdateResult = {
  __typename?: 'PropositionUpdateResult';
  proposition?: Maybe<Proposition>;
  warning?: Maybe<Array<Scalars['JSONObject']>>;
};

export type PropositionCursorResult = {
  __typename?: 'PropositionCursorResult';
  nodes: Array<Proposition>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type PropositionListInput = {
  orderField: Scalars['String'];
  orderDirection: Scalars['String'];
  status?: InputMaybe<Array<Scalars['String']>>;
  hotelIdsList: Array<Scalars['String']>;
  contactString?: InputMaybe<Scalars['String']>;
};

export type PropositionRowTotal = {
  __typename?: 'PropositionRowTotal';
  total?: Maybe<Scalars['Float']>;
  totalExtra?: Maybe<Scalars['Float']>;
  totalExtraWithoutDiscount?: Maybe<Scalars['Float']>;
  totalDiscountExtra?: Maybe<Scalars['Float']>;
  totalHosting?: Maybe<Scalars['Float']>;
  totalHostingWithoutDiscount?: Maybe<Scalars['Float']>;
  totalDiscountHosting?: Maybe<Scalars['Float']>;
  totalDiscount?: Maybe<Scalars['Float']>;
};

export type PropositionRowInput = {
  id?: InputMaybe<Scalars['String']>;
  arrivalDate?: InputMaybe<Scalars['DateTime']>;
  departureDate?: InputMaybe<Scalars['DateTime']>;
  paymentCondition?: InputMaybe<PropositionRowPaymentConditionEnum | `${PropositionRowPaymentConditionEnum}`>;
  comment?: InputMaybe<Scalars['String']>;
  roomList: Array<PropositionRowRoomInput>;
};

export type PropositionRowRoomInput = {
  roomIndex: Scalars['Float'];
  discount?: InputMaybe<DiscountInput>;
  isUpgrade?: InputMaybe<Scalars['Boolean']>;
  itemList: Array<PropositionRowRoomNightInput>;
  optionList?: InputMaybe<Array<PropositionRowRoomOptionInput>>;
};

export type DiscountInput = {
  forced?: InputMaybe<Scalars['Float']>;
  percent?: InputMaybe<Scalars['String']>;
};

export type PropositionRowRoomNightInput = {
  roomId?: InputMaybe<Scalars['String']>;
  dateStamp: Scalars['Float'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  rateId?: InputMaybe<Scalars['String']>;
  rateCode?: InputMaybe<Scalars['String']>;
  rateTypeId?: InputMaybe<Scalars['String']>;
  rateIncVat?: InputMaybe<Scalars['Float']>;
  rateExcVat?: InputMaybe<Scalars['Float']>;
  priceRateList?: InputMaybe<Array<Scalars['JSONObject']>>;
  error?: InputMaybe<Scalars['String']>;
  discountPushed?: InputMaybe<Scalars['Boolean']>;
  pmsId?: InputMaybe<Scalars['String']>;
  confirmedPush?: InputMaybe<Scalars['Boolean']>;
};

export type PropositionRowRoomOptionInput = {
  optionId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  amountIncVat?: InputMaybe<Scalars['Float']>;
  discount?: InputMaybe<DiscountInput>;
  optionPushed?: InputMaybe<Scalars['Boolean']>;
  error?: InputMaybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  codeShort: Scalars['String'];
  codeLong: Scalars['String'];
  name: Scalars['String'];
  phonePrefix: Scalars['String'];
};

export type ContactDedupListResult = {
  __typename?: 'ContactDedupListResult';
  nodes: Array<ContactDedup>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type ContactDedup = {
  __typename?: 'ContactDedup';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  contactLeftId?: Maybe<Scalars['String']>;
  contactRightId?: Maybe<Scalars['String']>;
  status?: Maybe<ContactDedupStatusEnum | `${ContactDedupStatusEnum}`>;
  isDuplicate?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<Hotel>;
  ContactLeft?: Maybe<Contact>;
  ContactRight?: Maybe<Contact>;
};

export enum ContactDedupStatusEnum {
  STATUS_TODO = 'STATUS_TODO',
  STATUS_DONE = 'STATUS_DONE'
}

export type ContactDedupListInput = {
  orderField: Scalars['String'];
  hotelIdsList: Array<Scalars['String']>;
  orderDirection: Scalars['String'];
  status?: InputMaybe<Array<ContactDedupStatusEnum | `${ContactDedupStatusEnum}`>>;
};

export type ConfirmationListResult = {
  __typename?: 'ConfirmationListResult';
  nodes: Array<Confirmation>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type Confirmation = {
  __typename?: 'Confirmation';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  reservationId?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  reservationGroupId?: Maybe<Scalars['String']>;
  status: ConfirmationStatusEnum | `${ConfirmationStatusEnum}`;
  sentDate?: Maybe<Scalars['DateTime']>;
  creationDate: Scalars['DateTime'];
  type?: Maybe<ConfirmationTypeEnum | `${ConfirmationTypeEnum}`>;
  mailMessageId?: Maybe<Scalars['String']>;
  confirmationData?: Maybe<ConfirmationData>;
  Reservation?: Maybe<Reservation>;
  ReservationGroup?: Maybe<ReservationGroup>;
  Hotel?: Maybe<Hotel>;
  Contact?: Maybe<Contact>;
  Answer?: Maybe<Array<ConfirmationAnswer>>;
  MailMessage?: Maybe<MailMessage>;
  ServiceEvent?: Maybe<Array<Event>>;
  ConfirmationConfig?: Maybe<ConfirmationConfig>;
};

export enum ConfirmationStatusEnum {
  CONFIRMATION_STATUS_DELETED = 'CONFIRMATION_STATUS_DELETED',
  CONFIRMATION_STATUS_TO_ANSWER = 'CONFIRMATION_STATUS_TO_ANSWER',
  CONFIRMATION_STATUS_TO_VALID = 'CONFIRMATION_STATUS_TO_VALID',
  CONFIRMATION_STATUS_TO_SEND = 'CONFIRMATION_STATUS_TO_SEND',
  CONFIRMATION_STATUS_SENT = 'CONFIRMATION_STATUS_SENT',
  CONFIRMATION_STATUS_DELIVERED = 'CONFIRMATION_STATUS_DELIVERED',
  CONFIRMATION_STATUS_READ = 'CONFIRMATION_STATUS_READ',
  CONFIRMATION_STATUS_ERROR = 'CONFIRMATION_STATUS_ERROR'
}

export enum ConfirmationTypeEnum {
  CONFIRMATION_TYPE_AUTO = 'CONFIRMATION_TYPE_AUTO',
  CONFIRMATION_TYPE_MANUAL = 'CONFIRMATION_TYPE_MANUAL',
  CONFIRMATION_TYPE_QUESTION = 'CONFIRMATION_TYPE_QUESTION',
  CONFIRMATION_TYPE_DATA = 'CONFIRMATION_TYPE_DATA'
}

export type ConfirmationData = {
  __typename?: 'ConfirmationData';
  hasParking?: Maybe<Scalars['Boolean']>;
  parking?: Maybe<ConfirmationDataParkingEnum | `${ConfirmationDataParkingEnum}`>;
  comment?: Maybe<Scalars['String']>;
  arrhesAmountInCents?: Maybe<Scalars['Float']>;
};

export enum ConfirmationDataParkingEnum {
  PARKING_FULL = 'PARKING_FULL',
  HAS_PARKING = 'HAS_PARKING',
  NO_PARKING = 'NO_PARKING',
  FREE_PARKING = 'FREE_PARKING'
}

export type ConfirmationAnswer = {
  __typename?: 'ConfirmationAnswer';
  id: Scalars['ID'];
  questionId: Scalars['String'];
  confirmationId: Scalars['String'];
  varId: Scalars['String'];
  datas: Scalars['JSONObject'];
};

export type ConfirmationListInput = {
  orderField: Scalars['String'];
  hotelIdsList: Array<Scalars['String']>;
  orderDirection: Scalars['String'];
  status?: InputMaybe<Array<Scalars['String']>>;
  pmsId?: InputMaybe<Scalars['String']>;
  contactString?: InputMaybe<Scalars['String']>;
};

export type ConfirmationQuestion = {
  __typename?: 'ConfirmationQuestion';
  id: Scalars['ID'];
  parentId: Scalars['String'];
  varId: Scalars['String'];
  hotelId: Scalars['String'];
  priority: Scalars['Float'];
  labelFR: Scalars['String'];
  answerType: ConfirmationQuestionAnswerTypeEnum | `${ConfirmationQuestionAnswerTypeEnum}`;
};

export enum ConfirmationQuestionAnswerTypeEnum {
  TYPE_DATE = 'TYPE_DATE',
  TYPE_DATETIME = 'TYPE_DATETIME',
  TYPE_DATETIME_FULL = 'TYPE_DATETIME_FULL',
  TYPE_TIME = 'TYPE_TIME',
  TYPE_TEXT = 'TYPE_TEXT',
  TYPE_NUMBER = 'TYPE_NUMBER',
  TYPE_BOOLEAN = 'TYPE_BOOLEAN',
  TYPE_LONGTEXT = 'TYPE_LONGTEXT',
  TYPE_SERVICE = 'TYPE_SERVICE',
  TYPE_AMOUNT = 'TYPE_AMOUNT'
}

export type PreStay = {
  __typename?: 'PreStay';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  reservationId?: Maybe<Scalars['String']>;
  reservationGroupId?: Maybe<Scalars['String']>;
  mailMessageId?: Maybe<Scalars['String']>;
  status?: Maybe<PreStayStatusEnum | `${PreStayStatusEnum}`>;
  answerStatus?: Maybe<PreStayAnswerStatusEnum | `${PreStayAnswerStatusEnum}`>;
  preStayContent?: Maybe<PreStayContent>;
  Reservation?: Maybe<Reservation>;
  ReservationGroup?: Maybe<ReservationGroup>;
  Checkin?: Maybe<Checkin>;
  Hotel?: Maybe<Hotel>;
  Contact?: Maybe<Contact>;
  creationDate?: Maybe<Scalars['DateTime']>;
  optionList?: Maybe<Array<Option>>;
  serviceList?: Maybe<Array<Service>>;
  EventList?: Maybe<Array<Event>>;
};

export enum PreStayStatusEnum {
  PRE_STAY_STATUS_INITIAL = 'PRE_STAY_STATUS_INITIAL',
  PRE_STAY_STATUS_DELETED = 'PRE_STAY_STATUS_DELETED',
  PRE_STAY_STATUS_TO_SEND = 'PRE_STAY_STATUS_TO_SEND',
  PRE_STAY_STATUS_SENT = 'PRE_STAY_STATUS_SENT',
  PRE_STAY_STATUS_DELIVERED = 'PRE_STAY_STATUS_DELIVERED',
  PRE_STAY_STATUS_READ = 'PRE_STAY_STATUS_READ',
  PRE_STAY_STATUS_ERROR = 'PRE_STAY_STATUS_ERROR'
}

export enum PreStayAnswerStatusEnum {
  PRE_STAY_ANSWER_STATUS_NOT_ANSWERED = 'PRE_STAY_ANSWER_STATUS_NOT_ANSWERED',
  PRE_STAY_ANSWER_STATUS_ANSWERED = 'PRE_STAY_ANSWER_STATUS_ANSWERED'
}

export type PreStayContent = {
  __typename?: 'PreStayContent';
  arrival?: Maybe<PreStayArrival>;
  comment?: Maybe<Scalars['String']>;
  optionsComment?: Maybe<Scalars['String']>;
  optionsSelected?: Maybe<Array<OptionSelected>>;
};

export type PreStayArrival = {
  __typename?: 'PreStayArrival';
  arrivalMinutes?: Maybe<Scalars['String']>;
  arrivalHours?: Maybe<Scalars['String']>;
  charging?: Maybe<Scalars['Boolean']>;
  parking?: Maybe<Scalars['Boolean']>;
  transfert?: Maybe<Scalars['Boolean']>;
  transfertData?: Maybe<PreStayArrivalTransfert>;
  comment?: Maybe<Scalars['String']>;
};

export type PreStayArrivalTransfert = {
  __typename?: 'PreStayArrivalTransfert';
  isTrain?: Maybe<Scalars['Boolean']>;
  isPlane?: Maybe<Scalars['Boolean']>;
  trainNumber?: Maybe<Scalars['String']>;
  trainStation?: Maybe<Scalars['String']>;
  arrivalDate?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['String']>;
  terminal?: Maybe<Scalars['String']>;
  airport?: Maybe<Scalars['String']>;
};

export type OptionSelected = {
  __typename?: 'OptionSelected';
  optionId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  Option?: Maybe<Option>;
};

export type PreStayListResult = {
  __typename?: 'PreStayListResult';
  nodes: Array<PreStay>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type PreStayListInput = {
  orderField: Scalars['String'];
  orderDirection: Scalars['String'];
  hotelIdsList: Array<Scalars['String']>;
  reservationPmsId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export type PostStay = {
  __typename?: 'PostStay';
  id: Scalars['ID'];
  hotelId?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  reservationId?: Maybe<Scalars['String']>;
  reservationGroupId?: Maybe<Scalars['String']>;
  mailMessageId?: Maybe<Scalars['String']>;
  alertTrigger?: Maybe<Scalars['Boolean']>;
  status?: Maybe<PostStayStatusEnum | `${PostStayStatusEnum}`>;
  sendStatus?: Maybe<PostStaySendStatusEnum | `${PostStaySendStatusEnum}`>;
  responseStatus?: Maybe<PostStayResponseStatusEnum | `${PostStayResponseStatusEnum}`>;
  sendDateTarget?: Maybe<Scalars['DateTime']>;
  sentDate?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['JSONObject']>;
  reason?: Maybe<Scalars['String']>;
  Reservation?: Maybe<Reservation>;
  ReservationGroup?: Maybe<ReservationGroup>;
  Hotel?: Maybe<Hotel>;
  Contact?: Maybe<Contact>;
  creationDate?: Maybe<Scalars['DateTime']>;
  PostStayConfig?: Maybe<PostStayConfig>;
  AnswerList?: Maybe<Array<Scalars['JSONObject']>>;
};

export enum PostStayStatusEnum {
  POST_STAY_STATUS_INITIAL = 'POST_STAY_STATUS_INITIAL',
  POST_STAY_STATUS_DELETED = 'POST_STAY_STATUS_DELETED',
  POST_STAY_STATUS_TO_SEND = 'POST_STAY_STATUS_TO_SEND',
  POST_STAY_STATUS_SENT = 'POST_STAY_STATUS_SENT',
  POST_STAY_STATUS_DELIVERED = 'POST_STAY_STATUS_DELIVERED',
  POST_STAY_STATUS_READ = 'POST_STAY_STATUS_READ',
  POST_STAY_STATUS_ERROR = 'POST_STAY_STATUS_ERROR'
}

export enum PostStaySendStatusEnum {
  POST_STAY_SEND_STATUS_HOLD = 'POST_STAY_SEND_STATUS_HOLD',
  POST_STAY_SEND_STATUS_TODO = 'POST_STAY_SEND_STATUS_TODO'
}

export enum PostStayResponseStatusEnum {
  POST_STAY_RESPONSE_STATUS_ANSWERED = 'POST_STAY_RESPONSE_STATUS_ANSWERED',
  POST_STAY_RESPONSE_STATUS_TO_ANSWER = 'POST_STAY_RESPONSE_STATUS_TO_ANSWER'
}

export type PostStayListResult = {
  __typename?: 'PostStayListResult';
  nodes: Array<PostStay>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type PostStayListInput = {
  orderField: Scalars['String'];
  orderDirection: Scalars['String'];
  hotelIdsList: Array<Scalars['String']>;
  status?: InputMaybe<Array<Scalars['String']>>;
  sendStatus?: InputMaybe<Array<Scalars['String']>>;
  responseStatus?: InputMaybe<Array<Scalars['String']>>;
};

export type PostStayAnswerStatsFilterInput = {
  dimensionType?: InputMaybe<StatsDimensionType | `${StatsDimensionType}`>;
  dimensionDate?: InputMaybe<Scalars['DateTime']>;
};

export type CheckinListInput = {
  hotelIds?: InputMaybe<Array<Scalars['String']>>;
  arrivalDate?: InputMaybe<Scalars['DateTime']>;
  orderField?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
};

export type EventListResult = {
  __typename?: 'EventListResult';
  nodes?: Maybe<Array<Event>>;
  cursor?: Maybe<Cursor>;
  input?: Maybe<Scalars['JSONObject']>;
};

export type EventListInput = {
  hotelIdList?: InputMaybe<Array<Scalars['String']>>;
  orderField?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<EventStatusEnum | `${EventStatusEnum}`>>;
};

export type PlanningViewInput = {
  startDate?: InputMaybe<Scalars['DateTime']>;
  nbDays?: InputMaybe<Scalars['Float']>;
};

export type PlanningWeeklyInput = {
  monday?: InputMaybe<Scalars['Boolean']>;
  tuesday?: InputMaybe<Scalars['Boolean']>;
  wednesday?: InputMaybe<Scalars['Boolean']>;
  thursday?: InputMaybe<Scalars['Boolean']>;
  friday?: InputMaybe<Scalars['Boolean']>;
  saturday?: InputMaybe<Scalars['Boolean']>;
  sunday?: InputMaybe<Scalars['Boolean']>;
};

export type Planning = {
  __typename?: 'Planning';
  id: Scalars['ID'];
  hotelId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  rowList?: Maybe<Array<Scalars['JSONObject']>>;
  options?: Maybe<Scalars['JSONObject']>;
};

export type PaymentListResult = {
  __typename?: 'PaymentListResult';
  nodes: Array<Payment>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type PaymentListInput = {
  orderField: Scalars['String'];
  orderDirection: Scalars['String'];
  status?: InputMaybe<Array<PaymentStatusEnum | `${PaymentStatusEnum}`>>;
  captureType?: InputMaybe<Array<PaymentCaptureTypeEnum | `${PaymentCaptureTypeEnum}`>>;
  hotelIds?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  creationDate?: InputMaybe<Scalars['DateTime']>;
  captureDate?: InputMaybe<Scalars['DateTime']>;
};

export type TokenListResult = {
  __typename?: 'TokenListResult';
  nodes: Array<Token>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type TokenListInput = {
  orderField: Scalars['String'];
  orderDirection: Scalars['String'];
  status?: InputMaybe<Array<TokenStatusEnum | `${TokenStatusEnum}`>>;
  hotelIds?: InputMaybe<Array<Scalars['String']>>;
  contact?: InputMaybe<Scalars['String']>;
  creationDate?: InputMaybe<Scalars['DateTime']>;
  captureDate?: InputMaybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  userConfigAdd: UserConfig;
  userConfigUpdate: UserConfig;
  userImpersonate: User;
  userStopImpersonation: Scalars['Boolean'];
  userLoginByToken?: Maybe<LoginToken>;
  userAdd: User;
  userUpdate: User;
  userDesactivate: User;
  userDelete: Scalars['Boolean'];
  userReactivate: User;
  userLoginByGoogleToken?: Maybe<LoginToken>;
  handleSavedHook: Scalars['Boolean'];
  fileAdd?: Maybe<File>;
  fileEdit?: Maybe<File>;
  fileEncryptedAdd?: Maybe<FileEncrypted>;
  fileEncryptedEdit?: Maybe<FileEncrypted>;
  appLogLevelUpdate?: Maybe<Scalars['Boolean']>;
  pictureAdd?: Maybe<Scalars['JSONObject']>;
  mailMessageSend?: Maybe<MailMessage>;
  notificationSetRead?: Maybe<Notification>;
  notificationSetAllRead?: Maybe<Array<Notification>>;
  corpAdd?: Maybe<Corp>;
  corpEdit?: Maybe<Corp>;
  templateAdd: Template;
  templateDelete: Scalars['Boolean'];
  templateUpdate: Template;
  newsAdd?: Maybe<News>;
  newsUpdate?: Maybe<News>;
  newsDelete?: Maybe<Scalars['Boolean']>;
  accountItemFetchFromGmail: Scalars['Boolean'];
  customerRemap: Scalars['Boolean'];
  newsletterRegistrationAddByWidget: NewsletterRegistration;
  segmentationAdd: Segmentation;
  segmentationEdit: Segmentation;
  segmentationDelete: Scalars['Boolean'];
  segmentationSync: Segmentation;
  segmentationStop: Segmentation;
  sendinblueHookRemap?: Maybe<Scalars['Boolean']>;
  sendinblueSmsTest?: Maybe<Scalars['JSONObject']>;
  targetRegister: Target;
  targetUpdate: Target;
  messagePush?: Maybe<Message>;
  channelCustomerCreate?: Maybe<Channel>;
  channelTargetLeave?: Maybe<ChannelTarget>;
  channelTargetUpdate?: Maybe<ChannelTarget>;
  orderFetchAllFromWebsite: Scalars['Boolean'];
  reservationRemap: Scalars['JSONObject'];
  reservationImportByPmsId?: Maybe<Reservation>;
  reservationSyncById?: Maybe<Reservation>;
  reservationFetchUpdatedToday?: Maybe<Scalars['Boolean']>;
  reservationImportBatch?: Maybe<Scalars['Boolean']>;
  reservationAddEvent?: Maybe<Event>;
  reservationGroupAdd?: Maybe<ReservationGroup>;
  reservationGroupUpdate?: Maybe<ReservationGroup>;
  reservationGroupDelete?: Maybe<Scalars['Boolean']>;
  reservationRateRemap?: Maybe<Scalars['Boolean']>;
  reservationRoomRemap?: Maybe<Scalars['Boolean']>;
  pricePackageBlacklistAdd: ReservationPricePackageBlacklist;
  pricePackageBlacklistDelete: ReservationPricePackageBlacklist;
  roomImportFromPms?: Maybe<Array<Room>>;
  roomTypeFindOrCreateByTypeAndHotelId?: Maybe<RoomType>;
  propositionAdd: Proposition;
  propositionAddOrUpdate: PropositionUpdateResult;
  propositionDelete: Scalars['Boolean'];
  propositionUpdate: Proposition;
  propositionSelectRow: Proposition;
  propositionCancelSelection: Proposition;
  propositionConfirm: Proposition;
  propositionPushById: Proposition;
  propositionValid: Proposition;
  propositionSend: Proposition;
  contactRemap?: Maybe<Scalars['JSONObject']>;
  contactFetchFromToday?: Maybe<Scalars['JSONObject']>;
  contactAdd?: Maybe<Contact>;
  contactEdit?: Maybe<Contact>;
  contactDedup?: Maybe<Contact>;
  fetcherAdd: Fetcher;
  hotelUpdate: Hotel;
  hotelTest: Hotel;
  hotelAdd: Hotel;
  mailSendTest?: Maybe<Scalars['JSONObject']>;
  confirmationSendById: Scalars['Boolean'];
  confirmationCheckStatus: Confirmation;
  confirmationSendManualById: Confirmation;
  confirmationDeleteById: Scalars['Boolean'];
  confirmationAddEvent: Event;
  confirmationSetData: Confirmation;
  confirmationAnswerSave: ConfirmationAnswer;
  confirmationAnswerClear: Confirmation;
  preStayAnswerByIdAndPublicId?: Maybe<PreStay>;
  preStayAddEvent?: Maybe<Event>;
  preStayDeleteEvent?: Maybe<Scalars['Boolean']>;
  postStayAnswerByIdAndPublicId?: Maybe<PostStay>;
  postStayDelete?: Maybe<Scalars['Boolean']>;
  postStayCheckPostStayToSend?: Maybe<Scalars['Boolean']>;
  postStayAnswerStatsHandleDailyUpdate?: Maybe<Scalars['Boolean']>;
  postStayAnswerStatsHandleWeeklyUpdate?: Maybe<Scalars['Boolean']>;
  postStayAnswerStatsHandleUpdate?: Maybe<Scalars['Boolean']>;
  pmsInterfaceStop?: Maybe<Scalars['Boolean']>;
  pmsInterfaceStart?: Maybe<Scalars['Boolean']>;
  pmsInterfaceStopAll?: Maybe<Scalars['Boolean']>;
  pmsInterfaceStartAll?: Maybe<Scalars['Boolean']>;
  checkinUpdate?: Maybe<Checkin>;
  eventAdd: Event;
  eventEdit: Event;
  eventDelete: Scalars['Boolean'];
  eventTestBreakfast?: Maybe<Scalars['JSONObject']>;
  planningAdd: Planning;
  planningEdit: Planning;
  hotsoftContactCorpUpdate?: Maybe<Scalars['JSONObject']>;
  hotsoftContactCorpAddTest?: Maybe<Scalars['JSONObject']>;
  hotsoftReservationAdd?: Maybe<Scalars['JSONObject']>;
  reservationCreateRoomBlocked?: Maybe<Scalars['JSONObject']>;
  hotsoftRoomChangeStatus?: Maybe<Array<Scalars['JSONObject']>>;
  paymentAdd: Payment;
  paymentConfirm: Payment;
  paymentSend: Payment;
  paymentCapture: Payment;
  paymentRefund: Payment;
  paymentSetDeleted: Payment;
  tokenAdd: Token;
  tokenConfirm: Token;
  tokenSendEmail: Token;
  tokenSetDeleted: Token;
  chargeAdd?: Maybe<Charge>;
  chargeRefund?: Maybe<Charge>;
  terminalProcessToken?: Maybe<Terminal>;
  terminalCancelAction?: Maybe<Terminal>;
  splashScreen?: Maybe<Terminal>;
  protelContactImportTest?: Maybe<Scalars['JSONObject']>;
  protelContactImportFromImportId?: Maybe<Scalars['JSONObject']>;
  protelContactUpdateFromImportId?: Maybe<Scalars['JSONObject']>;
  protelReservationImportTest?: Maybe<Scalars['JSONObject']>;
  protelReservationImportFromImportId?: Maybe<Scalars['JSONObject']>;
  protelReservationUpdateFromImportId?: Maybe<Scalars['JSONObject']>;
  vegaContactImportTest?: Maybe<Scalars['JSONObject']>;
  vegaContactImportFromImportId?: Maybe<Scalars['JSONObject']>;
  vegaContactUpdateFromImportId?: Maybe<Scalars['JSONObject']>;
  vegaReservationImportTest?: Maybe<Scalars['JSONObject']>;
  vegaReservationImportFromImportId?: Maybe<Scalars['JSONObject']>;
  vegaReservationUpdateFromImportId?: Maybe<Scalars['JSONObject']>;
  migrationContactImportTest?: Maybe<Scalars['JSONObject']>;
  migrationContactImportFromImportId?: Maybe<Scalars['JSONObject']>;
  migrationContactUpdateFromImportId?: Maybe<Scalars['JSONObject']>;
  migrationReservationImport?: Maybe<Scalars['JSONObject']>;
  migrationReservationImportFromImportId?: Maybe<Scalars['JSONObject']>;
  migrationReservationUpdateFromImportId?: Maybe<Scalars['JSONObject']>;
  runnerStart?: Maybe<Runner>;
  runnerStop?: Maybe<Runner>;
  runnerHandleRetention?: Maybe<Scalars['Boolean']>;
  contactMetricCompute?: Maybe<Scalars['Boolean']>;
  contactMetricComputeByHotelId?: Maybe<Scalars['Boolean']>;
};


export type MutationuserConfigAddArgs = {
  input: UserConfigInput;
};


export type MutationuserConfigUpdateArgs = {
  input: UserConfigInput;
  id: Scalars['String'];
};


export type MutationuserImpersonateArgs = {
  id: Scalars['String'];
};


export type MutationuserLoginByTokenArgs = {
  input: LoginTokenInput;
};


export type MutationuserAddArgs = {
  input: UserInput;
};


export type MutationuserUpdateArgs = {
  id: Scalars['String'];
  input: UserInput;
};


export type MutationuserDesactivateArgs = {
  id: Scalars['String'];
};


export type MutationuserDeleteArgs = {
  id: Scalars['String'];
};


export type MutationuserReactivateArgs = {
  id: Scalars['String'];
};


export type MutationuserLoginByGoogleTokenArgs = {
  token: Scalars['String'];
};


export type MutationfileAddArgs = {
  input: FileInput;
};


export type MutationfileEditArgs = {
  input: FileInput;
  id: Scalars['String'];
};


export type MutationfileEncryptedAddArgs = {
  input: FileEncryptedInput;
};


export type MutationfileEncryptedEditArgs = {
  input: FileEncryptedInput;
  id: Scalars['String'];
};


export type MutationappLogLevelUpdateArgs = {
  level: Scalars['String'];
};


export type MutationpictureAddArgs = {
  input: Scalars['JSONObject'];
};


export type MutationmailMessageSendArgs = {
  id: Scalars['String'];
};


export type MutationnotificationSetReadArgs = {
  isRead: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationcorpAddArgs = {
  input: CorpInput;
};


export type MutationcorpEditArgs = {
  input: CorpInput;
  id: Scalars['String'];
};


export type MutationtemplateAddArgs = {
  hotelId: Scalars['String'];
  input: TemplateInput;
};


export type MutationtemplateDeleteArgs = {
  id: Scalars['String'];
};


export type MutationtemplateUpdateArgs = {
  input: TemplateInput;
  hotelId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationnewsAddArgs = {
  input: NewsInput;
};


export type MutationnewsUpdateArgs = {
  input: NewsInput;
  id: Scalars['String'];
};


export type MutationnewsDeleteArgs = {
  id: Scalars['String'];
};


export type MutationaccountItemFetchFromGmailArgs = {
  limit: Scalars['Float'];
};


export type MutationnewsletterRegistrationAddByWidgetArgs = {
  email: Scalars['String'];
  token: Scalars['String'];
  tokenId: Scalars['String'];
};


export type MutationsegmentationAddArgs = {
  input: SegmentationInput;
};


export type MutationsegmentationEditArgs = {
  input: SegmentationInput;
  id: Scalars['String'];
};


export type MutationsegmentationDeleteArgs = {
  id: Scalars['String'];
};


export type MutationsegmentationSyncArgs = {
  id: Scalars['String'];
};


export type MutationsegmentationStopArgs = {
  id: Scalars['String'];
};


export type MutationtargetRegisterArgs = {
  input: TargetRegisterInput;
};


export type MutationtargetUpdateArgs = {
  input: TargetUpdateInput;
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationmessagePushArgs = {
  input: MessageInput;
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationchannelCustomerCreateArgs = {
  options?: InputMaybe<Scalars['JSONObject']>;
  clientId: Scalars['String'];
  hotelId: Scalars['String'];
};


export type MutationchannelTargetLeaveArgs = {
  channelId: Scalars['String'];
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationchannelTargetUpdateArgs = {
  input: ChannelTargetInput;
  channelId: Scalars['String'];
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationreservationImportByPmsIdArgs = {
  pmsId: Scalars['String'];
  hotelId: Scalars['String'];
};


export type MutationreservationSyncByIdArgs = {
  reservationId: Scalars['String'];
};


export type MutationreservationFetchUpdatedTodayArgs = {
  hotelId: Scalars['String'];
};


export type MutationreservationImportBatchArgs = {
  endNumber: Scalars['Float'];
  startNumber: Scalars['Float'];
  hotelId: Scalars['String'];
};


export type MutationreservationAddEventArgs = {
  input: EventInput;
  id: Scalars['String'];
};


export type MutationreservationGroupAddArgs = {
  input: ReservationGroupEditInput;
};


export type MutationreservationGroupUpdateArgs = {
  input: ReservationGroupEditInput;
  id: Scalars['String'];
};


export type MutationreservationGroupDeleteArgs = {
  id: Scalars['String'];
};


export type MutationpricePackageBlacklistAddArgs = {
  hotelId: Scalars['String'];
  code: Scalars['String'];
};


export type MutationpricePackageBlacklistDeleteArgs = {
  id: Scalars['String'];
};


export type MutationroomImportFromPmsArgs = {
  hotelId: Scalars['String'];
};


export type MutationroomTypeFindOrCreateByTypeAndHotelIdArgs = {
  hotelId: Scalars['String'];
  type: Scalars['String'];
};


export type MutationpropositionAddArgs = {
  input: PropositionInput;
};


export type MutationpropositionAddOrUpdateArgs = {
  input: PropositionInput;
};


export type MutationpropositionDeleteArgs = {
  id: Scalars['String'];
};


export type MutationpropositionUpdateArgs = {
  input: PropositionInput;
  id: Scalars['String'];
};


export type MutationpropositionSelectRowArgs = {
  rowId: Scalars['String'];
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationpropositionCancelSelectionArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationpropositionConfirmArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationpropositionPushByIdArgs = {
  id: Scalars['String'];
};


export type MutationpropositionValidArgs = {
  input: PropositionValidInput;
  id: Scalars['String'];
};


export type MutationpropositionSendArgs = {
  id: Scalars['String'];
};


export type MutationcontactFetchFromTodayArgs = {
  hotelId: Scalars['String'];
};


export type MutationcontactAddArgs = {
  input: ContactInput;
  hotelId: Scalars['String'];
};


export type MutationcontactEditArgs = {
  input: ContactEditInput;
  id: Scalars['String'];
};


export type MutationfetcherAddArgs = {
  input: FetcherInput;
};


export type MutationhotelUpdateArgs = {
  input: HotelInput;
  id: Scalars['String'];
};


export type MutationhotelTestArgs = {
  id: Scalars['String'];
};


export type MutationhotelAddArgs = {
  input: HotelInput;
};


export type MutationmailSendTestArgs = {
  emailDest: Scalars['String'];
  hotelId: Scalars['String'];
};


export type MutationconfirmationSendByIdArgs = {
  id: Scalars['String'];
};


export type MutationconfirmationCheckStatusArgs = {
  id: Scalars['String'];
};


export type MutationconfirmationSendManualByIdArgs = {
  id: Scalars['String'];
};


export type MutationconfirmationDeleteByIdArgs = {
  id: Scalars['String'];
};


export type MutationconfirmationAddEventArgs = {
  event: EventInput;
  id: Scalars['String'];
};


export type MutationconfirmationSetDataArgs = {
  input: ConfirmationDataInput;
  id: Scalars['String'];
};


export type MutationconfirmationAnswerSaveArgs = {
  datas: Scalars['JSONObject'];
  confirmationId: Scalars['String'];
  questionId: Scalars['String'];
};


export type MutationconfirmationAnswerClearArgs = {
  confirmationId: Scalars['String'];
};


export type MutationpreStayAnswerByIdAndPublicIdArgs = {
  input: PreStayContentInput;
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationpreStayAddEventArgs = {
  input: EventPreStayInput;
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationpreStayDeleteEventArgs = {
  eventId: Scalars['String'];
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationpostStayAnswerByIdAndPublicIdArgs = {
  input: PostStayAnswerInput;
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationpostStayDeleteArgs = {
  id: Scalars['String'];
};


export type MutationpostStayAnswerStatsHandleUpdateArgs = {
  dimensionDate?: InputMaybe<Scalars['DateTime']>;
  type: Scalars['String'];
};


export type MutationpmsInterfaceStopArgs = {
  hotelId: Scalars['String'];
};


export type MutationpmsInterfaceStartArgs = {
  hotelId: Scalars['String'];
};


export type MutationcheckinUpdateArgs = {
  input: CheckinInput;
  id: Scalars['String'];
};


export type MutationeventAddArgs = {
  input: EventInput;
};


export type MutationeventEditArgs = {
  input: EventInput;
  id: Scalars['String'];
};


export type MutationeventDeleteArgs = {
  id: Scalars['String'];
};


export type MutationplanningAddArgs = {
  input: PlanningInput;
};


export type MutationplanningEditArgs = {
  input: PlanningInput;
  id: Scalars['String'];
};


export type MutationhotsoftContactCorpUpdateArgs = {
  pmsId: Scalars['String'];
  hotelId: Scalars['String'];
};


export type MutationhotsoftContactCorpAddTestArgs = {
  hotelId: Scalars['String'];
};


export type MutationhotsoftReservationAddArgs = {
  memo: Scalars['String'];
  nbSpecial: Scalars['Float'];
  nbChildren: Scalars['Float'];
  nbAdults: Scalars['Float'];
  roomNumber: Scalars['String'];
  status: Scalars['String'];
  roomType: Scalars['String'];
  priceRateCode: Scalars['String'];
  contactNumber: Scalars['String'];
  departureDate: Scalars['DateTime'];
  arrivalDate: Scalars['DateTime'];
  hotelId: Scalars['String'];
};


export type MutationreservationCreateRoomBlockedArgs = {
  memo: Scalars['String'];
  roomNumber: Scalars['String'];
  departureDate: Scalars['DateTime'];
  arrivalDate: Scalars['DateTime'];
  hotelId: Scalars['String'];
};


export type MutationhotsoftRoomChangeStatusArgs = {
  roomNumber: Scalars['String'];
  status: Scalars['String'];
  hotelId: Scalars['String'];
};


export type MutationpaymentAddArgs = {
  input: PaymentInput;
};


export type MutationpaymentConfirmArgs = {
  options?: InputMaybe<Scalars['JSONObject']>;
  id: Scalars['String'];
};


export type MutationpaymentSendArgs = {
  id: Scalars['String'];
};


export type MutationpaymentCaptureArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationpaymentRefundArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationpaymentSetDeletedArgs = {
  id: Scalars['String'];
};


export type MutationtokenAddArgs = {
  input: TokenInput;
};


export type MutationtokenConfirmArgs = {
  options?: InputMaybe<Scalars['JSONObject']>;
  id: Scalars['String'];
};


export type MutationtokenSendEmailArgs = {
  id: Scalars['String'];
};


export type MutationtokenSetDeletedArgs = {
  id: Scalars['String'];
};


export type MutationchargeAddArgs = {
  input: ChargeInput;
  tokenId: Scalars['String'];
};


export type MutationchargeRefundArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationterminalProcessTokenArgs = {
  tokenId: Scalars['String'];
  terminalId: Scalars['String'];
};


export type MutationterminalCancelActionArgs = {
  terminalId: Scalars['String'];
};


export type MutationsplashScreenArgs = {
  terminalId: Scalars['String'];
};


export type MutationprotelContactImportTestArgs = {
  hotelId: Scalars['String'];
};


export type MutationprotelContactImportFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationprotelContactUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationprotelReservationImportTestArgs = {
  hotelId: Scalars['String'];
};


export type MutationprotelReservationImportFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationprotelReservationUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationvegaContactImportTestArgs = {
  hotelId: Scalars['String'];
};


export type MutationvegaContactImportFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationvegaContactUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationvegaReservationImportTestArgs = {
  hotelId: Scalars['String'];
};


export type MutationvegaReservationImportFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationvegaReservationUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationmigrationContactImportTestArgs = {
  hotelId: Scalars['String'];
};


export type MutationmigrationContactImportFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationmigrationContactUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationmigrationReservationImportArgs = {
  hotelId: Scalars['String'];
};


export type MutationmigrationReservationImportFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationmigrationReservationUpdateFromImportIdArgs = {
  importId: Scalars['String'];
};


export type MutationrunnerStartArgs = {
  id: Scalars['String'];
};


export type MutationrunnerStopArgs = {
  id: Scalars['String'];
};


export type MutationcontactMetricComputeByHotelIdArgs = {
  hotelId: Scalars['String'];
};

export type UserConfigInput = {
  name?: InputMaybe<Scalars['String']>;
  datas?: InputMaybe<UserConfigDataInput>;
};

export type UserConfigDataInput = {
  title?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  fontFamily?: InputMaybe<Scalars['String']>;
  themeMainColor?: InputMaybe<Scalars['String']>;
};

export type LoginToken = {
  __typename?: 'LoginToken';
  token: Scalars['String'];
  refresh?: Maybe<Scalars['String']>;
  expiration: Scalars['Float'];
};

export type LoginTokenInput = {
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type UserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  corpId?: InputMaybe<Scalars['String']>;
  PhoneAgentConfig?: InputMaybe<PhoneAgentConfigInput>;
  roles?: InputMaybe<Array<UserRoleEnum | `${UserRoleEnum}`>>;
  hotels?: InputMaybe<Array<Scalars['String']>>;
  clearPassword?: InputMaybe<Scalars['String']>;
  configId?: InputMaybe<Scalars['String']>;
  phoneSipConfig?: InputMaybe<PhoneSipConfigInput>;
  notificationListToMail?: InputMaybe<Array<NotificationTypeEnum | `${NotificationTypeEnum}`>>;
};

export type PhoneAgentConfigInput = {
  id?: InputMaybe<Scalars['String']>;
  phoneConfigId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  accountName?: InputMaybe<Scalars['String']>;
  callChannel?: InputMaybe<Scalars['String']>;
};

export type PhoneSipConfigInput = {
  id?: InputMaybe<Scalars['String']>;
  server?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type FileInput = {
  id?: InputMaybe<Scalars['ID']>;
  hotelId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  base64Buffer?: InputMaybe<Scalars['String']>;
};

export type FileEncryptedInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  base64PlainBuffer?: InputMaybe<Scalars['String']>;
};

export type CorpInput = {
  name?: InputMaybe<Scalars['String']>;
  hotelIdList?: InputMaybe<Array<Scalars['String']>>;
};

export type TemplateInput = {
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TemplateTypeEnum | `${TemplateTypeEnum}`>;
  contentFrHTML?: InputMaybe<Scalars['String']>;
  contentFrJSON?: InputMaybe<Scalars['JSONObject']>;
  contentEnHTML?: InputMaybe<Scalars['String']>;
  contentEnJSON?: InputMaybe<Scalars['JSONObject']>;
};

export type NewsInput = {
  level?: InputMaybe<NewsLevelEnum | `${NewsLevelEnum}`>;
  date?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  contentHTML?: InputMaybe<Scalars['String']>;
  contentJSON?: InputMaybe<Scalars['JSONObject']>;
};

export type SegmentationInput = {
  name?: InputMaybe<Scalars['String']>;
  displayCustomerDashboard?: InputMaybe<Scalars['Boolean']>;
  picto?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<SegmentationOptionsInput>;
};

export type TargetRegisterInput = {
  id?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  type: TargetTypeEnum | `${TargetTypeEnum}`;
  hotelIds: Array<Scalars['String']>;
};

export type TargetUpdateInput = {
  status: TargetStatusEnum | `${TargetStatusEnum}`;
};

export type MessageInput = {
  channelId: Scalars['String'];
  content: Array<MessageContentInput>;
};

export type MessageContentInput = {
  type: MessageContentTypeEnum | `${MessageContentTypeEnum}`;
  value: Scalars['String'];
  options: Scalars['JSONObject'];
};

export type ChannelTargetInput = {
  isWriting: Scalars['Boolean'];
};

export type EventInput = {
  utcDate?: InputMaybe<Scalars['DateTime']>;
  serviceId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EventStatusEnum | `${EventStatusEnum}`>;
  source?: InputMaybe<Scalars['JSONObject']>;
};

export type ReservationGroupEditInput = {
  arrivalDate?: InputMaybe<Scalars['DateTime']>;
  departureDate?: InputMaybe<Scalars['DateTime']>;
  contactId?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['String']>;
  ReservationGroupLink?: InputMaybe<Array<ReservationGroupLinkInput>>;
};

export type ReservationGroupLinkInput = {
  id?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  reservationId?: InputMaybe<Scalars['String']>;
};

export type PropositionInput = {
  id?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  nbRooms?: InputMaybe<Scalars['Float']>;
  nbAdults?: InputMaybe<Scalars['Float']>;
  nbChildren?: InputMaybe<Scalars['Float']>;
  nbSpecial?: InputMaybe<Scalars['Float']>;
  pushPms?: InputMaybe<Scalars['String']>;
  propositionRow?: InputMaybe<Array<PropositionRowInput>>;
  comment?: InputMaybe<Scalars['String']>;
};

export type PropositionValidInput = {
  id?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  expirationDate?: InputMaybe<Scalars['DateTime']>;
  nbRooms?: InputMaybe<Scalars['Float']>;
  nbAdults?: InputMaybe<Scalars['Float']>;
  nbChildren?: InputMaybe<Scalars['Float']>;
  nbSpecial?: InputMaybe<Scalars['Float']>;
  pushPms?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  propositionRow: Array<PropositionRowValidInput>;
};

export type PropositionRowValidInput = {
  id?: InputMaybe<Scalars['String']>;
  arrivalDate?: InputMaybe<Scalars['DateTime']>;
  departureDate?: InputMaybe<Scalars['DateTime']>;
  comment?: InputMaybe<Scalars['String']>;
  paymentCondition?: InputMaybe<PropositionRowPaymentConditionEnum | `${PropositionRowPaymentConditionEnum}`>;
  roomList: Array<PropositionRowRoomValidInput>;
};

export type PropositionRowRoomValidInput = {
  roomIndex: Scalars['Float'];
  isUpgrade?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<DiscountInput>;
  itemList?: InputMaybe<Array<PropositionRowRoomNightValidInput>>;
  optionList?: InputMaybe<Array<PropositionRowRoomOptionInput>>;
};

export type PropositionRowRoomNightValidInput = {
  roomId?: InputMaybe<Scalars['String']>;
  dateStamp: Scalars['Float'];
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  rateId?: InputMaybe<Scalars['String']>;
  rateCode?: InputMaybe<Scalars['String']>;
  rateIncVat?: InputMaybe<Scalars['Float']>;
  rateExcVat?: InputMaybe<Scalars['Float']>;
  priceRateList?: InputMaybe<Array<Scalars['JSONObject']>>;
  discountPushed?: InputMaybe<Scalars['Boolean']>;
  pmsId?: InputMaybe<Scalars['String']>;
  confirmedPush?: InputMaybe<Scalars['Boolean']>;
  error?: InputMaybe<Scalars['String']>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address3?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
  nationalityCode?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  forceIsFrench?: InputMaybe<Scalars['Boolean']>;
};

export type ContactEditInput = {
  forceIsFrench?: InputMaybe<Scalars['Boolean']>;
};

export type Fetcher = {
  __typename?: 'Fetcher';
  id: Scalars['ID'];
  start: Scalars['Float'];
  end: Scalars['Float'];
  isRunning?: Maybe<Scalars['Boolean']>;
  hasError?: Maybe<Scalars['Boolean']>;
  hotelId: Scalars['String'];
  Hotel?: Maybe<Hotel>;
  type?: Maybe<Scalars['String']>;
  currentNumber?: Maybe<Scalars['Float']>;
  currentError?: Maybe<Scalars['String']>;
};

export type FetcherInput = {
  type: FetcherTypeEnum | `${FetcherTypeEnum}`;
  start: Scalars['Float'];
  end: Scalars['Float'];
  hotelId: Scalars['String'];
};

export enum FetcherTypeEnum {
  TYPE_CONTACT = 'TYPE_CONTACT',
  TYPE_RESERVATION = 'TYPE_RESERVATION'
}

export type HotelInput = {
  configGeneral?: InputMaybe<HotelConfigGeneralInput>;
  configConfirmation?: InputMaybe<HotelConfigConfirmationInput>;
  confirmationConfig?: InputMaybe<ConfirmationConfigInput>;
  propositionConfig?: InputMaybe<PropositionConfigInput>;
  configOptions?: InputMaybe<HotelConfigOptionsInput>;
  configPriceRate?: InputMaybe<HotelConfigPriceRateInput>;
  configCancelConditions?: InputMaybe<HotelConfigCancelConditionsInput>;
  configRoomType?: InputMaybe<HotelConfigRoomTypeInput>;
  configRoom?: InputMaybe<Array<RoomInput>>;
  configBilling?: InputMaybe<BillingConfigInput>;
  configStay?: InputMaybe<HotelConfigStayInput>;
  configService?: InputMaybe<HotelConfigServiceInput>;
  configSeasonality?: InputMaybe<Array<SeasonalityInput>>;
  phoneConfig?: InputMaybe<PhoneConfigInput>;
  networkConfig?: InputMaybe<NetworkConfigInput>;
  googleConfig?: InputMaybe<GoogleConfigInput>;
  tripAdvisorConfig?: InputMaybe<TripAdvisorConfigInput>;
  pmsConfig?: InputMaybe<PmsConfigInput>;
  tokenList?: InputMaybe<Array<HotelTokenInput>>;
  videoConfig?: InputMaybe<VideoConfigInput>;
};

export type HotelConfigGeneralInput = {
  name?: InputMaybe<Scalars['String']>;
  emailSender?: InputMaybe<MailMessageAddressInput>;
  notificationDest?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  activeFetcher?: InputMaybe<Scalars['Boolean']>;
  checkInHour?: InputMaybe<Scalars['String']>;
  checkOutHour?: InputMaybe<Scalars['String']>;
  breakfastStartHour?: InputMaybe<Scalars['String']>;
  breakfastEndHour?: InputMaybe<Scalars['String']>;
  themeColor?: InputMaybe<Scalars['String']>;
  backgroundThemeColor?: InputMaybe<Scalars['String']>;
  cgvUrlEn?: InputMaybe<Scalars['String']>;
  cgvUrlFr?: InputMaybe<Scalars['String']>;
  cgvUrlEnFileId?: InputMaybe<Scalars['String']>;
  cgvUrlFrFileId?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  logoMonoUrl?: InputMaybe<Scalars['String']>;
  backgroundUrl?: InputMaybe<Scalars['String']>;
  stayTax?: InputMaybe<Scalars['Float']>;
  demoMode?: InputMaybe<Scalars['Boolean']>;
  displayRoomPictureDisclosure?: InputMaybe<Scalars['Boolean']>;
  displayRoomCategoryInDetails?: InputMaybe<Scalars['Boolean']>;
  notificationListToMail?: InputMaybe<Array<NotificationTypeEnum | `${NotificationTypeEnum}`>>;
};

export type MailMessageAddressInput = {
  name?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
};

export type HotelConfigConfirmationInput = {
  confirmationDest?: InputMaybe<Array<Scalars['String']>>;
  confirmationChannel?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  confirmationType?: InputMaybe<HotelConfirmationTypeEnum | `${HotelConfirmationTypeEnum}`>;
  confirmationTemplateFr?: InputMaybe<Scalars['String']>;
  confirmationTemplateEn?: InputMaybe<Scalars['String']>;
  confirmationDataTypeActive?: InputMaybe<Array<ConfirmationDataTypeEnum | `${ConfirmationDataTypeEnum}`>>;
  propositionTemplateFr?: InputMaybe<Scalars['String']>;
  propositionTemplateEn?: InputMaybe<Scalars['String']>;
};

export enum HotelConfirmationTypeEnum {
  HOTEL_CONF_AUTO = 'HOTEL_CONF_AUTO',
  HOTEL_CONF_QUESTION = 'HOTEL_CONF_QUESTION',
  HOTEL_CONF_MANUAL = 'HOTEL_CONF_MANUAL',
  HOTEL_CONF_DATA = 'HOTEL_CONF_DATA'
}

export enum ConfirmationDataTypeEnum {
  TYPE_PARKING = 'TYPE_PARKING',
  TYPE_COMMENT = 'TYPE_COMMENT',
  TYPE_ARRHES = 'TYPE_ARRHES',
  TYPE_SERVICES = 'TYPE_SERVICES'
}

export type ConfirmationConfigInput = {
  dest?: InputMaybe<Array<Scalars['String']>>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<ConfirmationConfigTypeEnum | `${ConfirmationConfigTypeEnum}`>;
  dataTypeActive?: InputMaybe<Array<ConfirmationConfigDataTypeEnum | `${ConfirmationConfigDataTypeEnum}`>>;
  demoMode?: InputMaybe<Scalars['Boolean']>;
  templateId?: InputMaybe<Scalars['String']>;
};

export type PropositionConfigInput = {
  templateId?: InputMaybe<Scalars['String']>;
  demoMode?: InputMaybe<Scalars['Boolean']>;
  dest?: InputMaybe<Array<Scalars['String']>>;
};

export type HotelConfigOptionsInput = {
  Option?: InputMaybe<Array<OptionInput>>;
};

export type OptionInput = {
  id?: InputMaybe<Scalars['String']>;
  pmsId: Scalars['String'];
  amountIncVat?: InputMaybe<Scalars['Float']>;
  canBeSelectedInProposition?: InputMaybe<Scalars['Boolean']>;
  canBeSelectedInPreStay?: InputMaybe<Scalars['Boolean']>;
  canBeDisplayedInConfirmation?: InputMaybe<Scalars['Boolean']>;
  amountExcVat?: InputMaybe<Scalars['Float']>;
  labelFr: Scalars['String'];
  labelEn?: InputMaybe<Scalars['String']>;
  fileId?: InputMaybe<Scalars['String']>;
  isBreakfast?: InputMaybe<Scalars['Boolean']>;
  isSpa?: InputMaybe<Scalars['Boolean']>;
  isRestaurant?: InputMaybe<Scalars['Boolean']>;
  isRestaurant2?: InputMaybe<Scalars['Boolean']>;
  isPerNight?: InputMaybe<Scalars['Boolean']>;
  isPerAdult?: InputMaybe<Scalars['Boolean']>;
  isPerChildren?: InputMaybe<Scalars['Boolean']>;
  isPerSpecial?: InputMaybe<Scalars['Boolean']>;
  descFr?: InputMaybe<Scalars['String']>;
  descEn?: InputMaybe<Scalars['String']>;
  picto?: InputMaybe<Scalars['String']>;
  seasonalityExclude?: InputMaybe<Array<Scalars['String']>>;
};

export type HotelConfigPriceRateInput = {
  PriceRateType?: InputMaybe<Array<PriceRateTypeInput>>;
};

export type PriceRateTypeInput = {
  id?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  isBreakfastIncluded?: InputMaybe<Scalars['Boolean']>;
  isRefundable?: InputMaybe<Scalars['Boolean']>;
  isGroup?: InputMaybe<Scalars['Boolean']>;
  isGiftBox?: InputMaybe<Scalars['Boolean']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  hasSpa?: InputMaybe<Scalars['Boolean']>;
  hasFood?: InputMaybe<Scalars['Boolean']>;
  hasDrink?: InputMaybe<Scalars['Boolean']>;
  hasService?: InputMaybe<Scalars['Boolean']>;
  canBeSelectedInProposition?: InputMaybe<Scalars['Boolean']>;
  isIncludedInConfirmationTotal?: InputMaybe<Scalars['Boolean']>;
  labelFr?: InputMaybe<Scalars['String']>;
  priceRateCancelConditionId?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
  conditionsFR?: InputMaybe<Scalars['String']>;
  conditionsEN?: InputMaybe<Scalars['String']>;
};

export type HotelConfigCancelConditionsInput = {
  PriceRateCancelCondition?: InputMaybe<Array<PriceRateCancelConditionInput>>;
};

export type PriceRateCancelConditionInput = {
  id?: InputMaybe<Scalars['String']>;
  toBeDeleted?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<PriceRateCancelConditionItemValueEnum | `${PriceRateCancelConditionItemValueEnum}`>;
  conditionList?: InputMaybe<Array<PriceRateCancelConditionItemInput>>;
  type?: InputMaybe<Scalars['String']>;
  functionText?: InputMaybe<Scalars['String']>;
};

export type PriceRateCancelConditionItemInput = {
  before?: InputMaybe<Scalars['Float']>;
  value?: InputMaybe<PriceRateCancelConditionItemValueEnum | `${PriceRateCancelConditionItemValueEnum}`>;
};

export type HotelConfigRoomTypeInput = {
  RoomType?: InputMaybe<Array<RoomTypeInput>>;
};

export type RoomTypeInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  canBeSelectedInProposition?: InputMaybe<Scalars['Boolean']>;
  labelFr?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
  descImgUrls?: InputMaybe<Array<Scalars['String']>>;
  descCapacityFr?: InputMaybe<Scalars['String']>;
  descTextFr?: InputMaybe<Scalars['String']>;
  descCapacityEn?: InputMaybe<Scalars['String']>;
  descTextEn?: InputMaybe<Scalars['String']>;
};

export type RoomInput = {
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  roomTypeId: Scalars['String'];
  floor?: InputMaybe<Scalars['String']>;
  nbBeds?: InputMaybe<Scalars['Float']>;
};

export type BillingConfigInput = {
  type?: InputMaybe<BillingConfigTypeEnum | `${BillingConfigTypeEnum}`>;
  templateId?: InputMaybe<Scalars['String']>;
  stripeConfig?: InputMaybe<StripeConfigInput>;
  terminalList?: InputMaybe<Array<TerminalInput>>;
};

export type StripeConfigInput = {
  secretKey?: InputMaybe<Scalars['String']>;
  publishableKey?: InputMaybe<Scalars['String']>;
  webHookKey?: InputMaybe<Scalars['String']>;
};

export type TerminalInput = {
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<TerminalTypeEnum | `${TerminalTypeEnum}`>;
  name?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  stripeOptions?: InputMaybe<StripeTerminalOptionsInput>;
};

export type StripeTerminalOptionsInput = {
  stripeTerminalId?: InputMaybe<Scalars['String']>;
};

export type HotelConfigStayInput = {
  postStayConfig?: InputMaybe<PostStayConfigInput>;
  preStayConfig?: InputMaybe<PreStayConfigInput>;
};

export type PostStayConfigInput = {
  templateId?: InputMaybe<Scalars['String']>;
  postStayActive?: InputMaybe<Scalars['Boolean']>;
  alertThreshold?: InputMaybe<Scalars['Float']>;
  dest?: InputMaybe<Array<Scalars['String']>>;
  demoMode?: InputMaybe<Scalars['Boolean']>;
  nbDaysAfterDeparture?: InputMaybe<Scalars['Float']>;
  sendHour?: InputMaybe<Scalars['Float']>;
  channel?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  PostStayGroup?: InputMaybe<Array<PostStayGroupInput>>;
};

export type PostStayGroupInput = {
  id?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Float']>;
  labelFr?: InputMaybe<Scalars['String']>;
  descFr?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
  descEn?: InputMaybe<Scalars['String']>;
  PostStayGroupItem?: InputMaybe<Array<PostStayGroupItemInput>>;
};

export type PostStayGroupItemInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PostStayGroupItemTypeEnum | `${PostStayGroupItemTypeEnum}`>;
  Question?: InputMaybe<PostStayQuestionInput>;
  Separator?: InputMaybe<PostStayGroupSeparatorInput>;
};

export type PostStayQuestionInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PostStayQuestionTypeEnum | `${PostStayQuestionTypeEnum}`>;
  name?: InputMaybe<Scalars['String']>;
  labelFr?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
  descFr?: InputMaybe<Scalars['String']>;
  descEn?: InputMaybe<Scalars['String']>;
  conditionKey?: InputMaybe<Scalars['String']>;
  conditionValue?: InputMaybe<Scalars['String']>;
  alertValue?: InputMaybe<Scalars['String']>;
  choiceList?: InputMaybe<Array<Scalars['JSONObject']>>;
};

export type PostStayGroupSeparatorInput = {
  id?: InputMaybe<Scalars['String']>;
  labelFr?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
};

export type PreStayConfigInput = {
  preStayGroup?: InputMaybe<Array<PreStayGroupInput>>;
};

export type PreStayGroupInput = {
  id?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Float']>;
  labelFr?: InputMaybe<Scalars['String']>;
  descFr?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
  descEn?: InputMaybe<Scalars['String']>;
  questionList?: InputMaybe<Array<PreStayGroupQuestionInput>>;
};

export type PreStayGroupQuestionInput = {
  labelFr?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PreStayGroupQuestionTypeEnum | `${PreStayGroupQuestionTypeEnum}`>;
  questionListOnTrue?: InputMaybe<Array<PreStayGroupQuestionInput>>;
  questionListOnFalse?: InputMaybe<Array<PreStayGroupQuestionInput>>;
};

export type HotelConfigServiceInput = {
  ServiceList?: InputMaybe<Array<ServiceInput>>;
};

export type ServiceInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  labelFr?: InputMaybe<Scalars['String']>;
  descFr?: InputMaybe<Scalars['String']>;
  labelEn?: InputMaybe<Scalars['String']>;
  descEn?: InputMaybe<Scalars['String']>;
  pictoUrl?: InputMaybe<Scalars['String']>;
  autoAddBreakfast?: InputMaybe<Scalars['Boolean']>;
  canBeSelectedInPreStay?: InputMaybe<Scalars['Boolean']>;
  canBeSelectedInConfirmation?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<ServiceTypeEnum | `${ServiceTypeEnum}`>;
  providerType?: InputMaybe<ServiceProviderTypeEnum | `${ServiceProviderTypeEnum}`>;
  managerUserId?: InputMaybe<Scalars['String']>;
  planningWeekly?: InputMaybe<PlanningWeeklyInput>;
};

export type SeasonalityInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDay?: InputMaybe<Scalars['Float']>;
  startMonth?: InputMaybe<Scalars['Float']>;
  endDay?: InputMaybe<Scalars['Float']>;
  endMonth?: InputMaybe<Scalars['Float']>;
};

export type PhoneConfigInput = {
  hotelId?: InputMaybe<Scalars['String']>;
  phoneConfigId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  clic2CallSequenceId?: InputMaybe<Scalars['String']>;
};

export type NetworkConfigInput = {
  hostingConfigId?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type GoogleConfigInput = {
  placeId?: InputMaybe<Scalars['String']>;
};

export type TripAdvisorConfigInput = {
  reviewUrl?: InputMaybe<Scalars['String']>;
};

export type PmsConfigInput = {
  type?: InputMaybe<PmsConfigTypeEnum | `${PmsConfigTypeEnum}`>;
  hotsoftConfig?: InputMaybe<PmsConfigHotsoftInput>;
};

export type PmsConfigHotsoftInput = {
  providerKey?: InputMaybe<Scalars['String']>;
  hotelCode?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  productCodeForReservationDiscount?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
};

export type HotelTokenInput = {
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<HotelTokenStatusEnum | `${HotelTokenStatusEnum}`>;
  label?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['DateTime']>;
};

export type VideoConfigInput = {
  id?: InputMaybe<Scalars['ID']>;
  storageDir?: InputMaybe<Scalars['String']>;
  CameraList?: InputMaybe<Array<CameraInput>>;
};

export type CameraInput = {
  id?: InputMaybe<Scalars['ID']>;
  videoConfigId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  port?: InputMaybe<Scalars['Float']>;
  protocol?: InputMaybe<CameraProtocolEnum | `${CameraProtocolEnum}`>;
  codec?: InputMaybe<CameraCodecEnum | `${CameraCodecEnum}`>;
  retentionDays?: InputMaybe<Scalars['Float']>;
  streamingType?: InputMaybe<CameraStreamingTypeEnum | `${CameraStreamingTypeEnum}`>;
};

export type ConfirmationDataInput = {
  parking?: InputMaybe<ConfirmationDataParkingEnum | `${ConfirmationDataParkingEnum}`>;
  hasParking?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  arrhesAmountInCents?: InputMaybe<Scalars['Float']>;
};

export type PreStayContentInput = {
  comment?: InputMaybe<Scalars['String']>;
  optionsComment?: InputMaybe<Scalars['String']>;
  arrival?: InputMaybe<PreStayContentArrivalInput>;
  checkin?: InputMaybe<CheckinDetailsInput>;
  optionsSelected?: InputMaybe<Array<OptionSelectedInput>>;
  resourceEventList?: InputMaybe<Array<Scalars['String']>>;
};

export type PreStayContentArrivalInput = {
  arrivalMinutes?: InputMaybe<Scalars['String']>;
  arrivalHours?: InputMaybe<Scalars['String']>;
  charging?: InputMaybe<Scalars['Boolean']>;
  parking?: InputMaybe<Scalars['Boolean']>;
  transfert?: InputMaybe<Scalars['Boolean']>;
  transfertData?: InputMaybe<PreStayArrivalTransfertInput>;
  comment?: InputMaybe<Scalars['String']>;
};

export type PreStayArrivalTransfertInput = {
  isTrain?: InputMaybe<Scalars['Boolean']>;
  isPlane?: InputMaybe<Scalars['Boolean']>;
  trainNumber?: InputMaybe<Scalars['String']>;
  trainStation?: InputMaybe<Scalars['String']>;
  arrivalDate?: InputMaybe<Scalars['String']>;
  flightNumber?: InputMaybe<Scalars['String']>;
  terminal?: InputMaybe<Scalars['String']>;
  airport?: InputMaybe<Scalars['String']>;
};

export type CheckinDetailsInput = {
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  mail?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
};

export type OptionSelectedInput = {
  optionId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
};

export type EventPreStayInput = {
  utcDate?: InputMaybe<Scalars['DateTime']>;
  serviceId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['JSONObject']>;
};

export type PostStayAnswerInput = {
  answerList?: InputMaybe<Array<PostStayAnswerItemInput>>;
};

export type PostStayAnswerItemInput = {
  questionId?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type CheckinInput = {
  details?: InputMaybe<CheckinDetailsInput>;
  identity?: InputMaybe<CheckinIdentityInput>;
  billing?: InputMaybe<CheckinBillingInput>;
};

export type CheckinIdentityInput = {
  checkinIdentityPicture?: InputMaybe<Array<CheckinIdentityPictureInput>>;
};

export type CheckinIdentityPictureInput = {
  id?: InputMaybe<Scalars['String']>;
  encryptedFileId?: InputMaybe<Scalars['String']>;
};

export type CheckinBillingInput = {
  tokenId?: InputMaybe<Scalars['String']>;
};

export type PlanningInput = {
  name?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['String']>;
  rowList?: InputMaybe<Array<Scalars['JSONObject']>>;
  options?: InputMaybe<PlanningOptionsInput>;
};

export type PlanningOptionsInput = {
  serviceIdList?: InputMaybe<Array<Scalars['String']>>;
  rowHeight?: InputMaybe<Scalars['Float']>;
};

export type PaymentInput = {
  hotelId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  mobilePhoneNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
  captureType?: InputMaybe<Scalars['String']>;
};

export type TokenInput = {
  hotelId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  terminalId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TokenTypeEnum | `${TokenTypeEnum}`>;
  notes?: InputMaybe<Scalars['String']>;
};

export type ChargeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<ChargeTypeEnum | `${ChargeTypeEnum}`>;
};

export type Subscription = {
  __typename?: 'Subscription';
  appLogs: Scalars['JSONObject'];
  notificationSubscription: Notification;
  segmentationUpdate?: Maybe<Segmentation>;
  segmentationDownloadProgress?: Maybe<Scalars['JSONObject']>;
  targetIsAgentAvailable?: Maybe<Scalars['Boolean']>;
  targetUpdate?: Maybe<Target>;
  messageNew?: Maybe<Message>;
  channelUpdate?: Maybe<Channel>;
  channelMessage?: Maybe<Message>;
  propositionUpdate: Proposition;
  propositionPushUpdate: Proposition;
  hotelUpdate: Hotel;
  confirmationUpdate: Confirmation;
  paymentUpdate: Payment;
  tokenUpdate: Token;
  chargeUpdate?: Maybe<Charge>;
  terminalUpdate?: Maybe<Terminal>;
  runnerUpdate?: Maybe<Runner>;
};


export type SubscriptionsegmentationDownloadProgressArgs = {
  id: Scalars['String'];
};


export type SubscriptiontargetUpdateArgs = {
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type SubscriptionmessageNewArgs = {
  channelId: Scalars['String'];
};


export type SubscriptionchannelUpdateArgs = {
  channelId: Scalars['String'];
  clientId: Scalars['String'];
  id: Scalars['String'];
};


export type SubscriptionchannelMessageArgs = {
  channelId: Scalars['String'];
};


export type SubscriptionpropositionUpdateArgs = {
  publicId: Scalars['String'];
  id: Scalars['String'];
};


export type SubscriptionpropositionPushUpdateArgs = {
  id: Scalars['String'];
};


export type SubscriptionpaymentUpdateArgs = {
  id: Scalars['String'];
};


export type SubscriptiontokenUpdateArgs = {
  id: Scalars['String'];
};


export type SubscriptionchargeUpdateArgs = {
  id: Scalars['String'];
};


export type SubscriptionterminalUpdateArgs = {
  id: Scalars['String'];
};


export type SubscriptionrunnerUpdateArgs = {
  id: Scalars['String'];
};
